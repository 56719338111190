import React from "react";
import './content.scss'



const Shipping = () => {
  return(
    <div style={{minHeight:'100vh'}}>
    <p className='termsHeading'>Shipping</p>
    <hr className='separate3' />
    <p className='text-color'>Shipping is not applicable for business.</p>
    </div>
  );
};

export default Shipping;