import React from "react";
import { Box, Typography, Avatar, Grid,Paper} from "@mui/material";
import StarIcon from '@mui/icons-material/Star';
import SideBar from "../../../components/sidebar";
import EmployeeListCard from "./EmployeeListCard";


const reviews = [
    {
      title: 'Great Quality!',
      text: 'I recently had a fantastic meal at this restaurant – the food was delicious and beautifully presented.',
      date: '2014-2016',
      logo: 'path/to/clearbiz_logo.png',
      company: 'Clearbiz.Ai',
    },
    {
      title: 'Amazing Work!',
      text: 'I recently had a fantastic meal at this restaurant – the food was delicious and beautifully presented.',
      date: '2014-2016',
      logo: 'path/to/google_logo.png',
      company: 'Google',
    },
    {
      title: 'Great Quality!',
      text: 'I recently had a fantastic meal at this restaurant – the food was delicious and beautifully presented.',
      date: '2014-2016',
      logo: 'path/to/microsoft_logo.png',
      company: 'Microsoft',
    },
  ];

function EmployeeDetails() {
  return (
    <>
    <SideBar/>
    <Box sx={{ mt: 12 }}>
        <EmployeeListCard/>
      <Paper sx={{ mt:'5%', width:'80%',pb:'5%',mb:'5%',pt:'2%', ml:{xs:'16%',md:'16%',sm:'20%'},boxShadow:' 1px 0px 40px 0px hsba(0, 0%, 0%, 1)',background: 'hsba(0, 0%, 100%, 1)'}}>
      <Typography variant="h5" sx={{ fontWeight: 'bold', marginBottom: 3 ,background: 'rgba(130, 178, 196, 1)',width:{xs:'40%',sm:'15%'},p:1}}>
        Ratings
      </Typography>
      <Grid container spacing={2} gap={4} mt={2} ml={5} width={{xs:'80%',sm:'79%',md:'100%'}}>
        {reviews.map((review, index) => (
          <Grid item xs={12} sm={5} md={3} key={index} textAlign={'center'}>
                <Box sx={{ display: 'flex', justifyContent: 'center', marginBottom: 1,marginRight:{sm:'0%',md:'0%',xs:'9%'} }}>
                  {Array.from({ length: 5 }).map((_, i) => (
                    <StarIcon key={i} sx={{ color: '#FFD700' }} />
                  ))}
                </Box>
                <Box>
                <Typography variant="h6" sx={{ fontWeight: 'bold',fontfamily:'Inter' }}>
                  {review.title}
                </Typography>
                <Typography variant="body2" color="#000" sx={{ marginTop: 1, marginBottom: 2,fontfamily:'Inter' }}>
                  {review.text}
                </Typography>
                <Typography variant="caption"  fontWeight='bold' color='#000'>
                  {review.date}
                </Typography>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: 2}}>
                  <Avatar src={review.logo} sx={{ width: 40, height: 40, marginRight: 1 }} />
                </Box>
                <Typography variant="body1" color='#000'>{review.company}</Typography>
          </Grid>
        ))}
      </Grid>
    </Paper>
    </Box>
    </>
  );
}

export default EmployeeDetails;
