import React, { useState } from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import Sidebar from '../../../components/sidebar';
import { TextField, Grid, Typography, Box, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import './styles.css';

function NewEmployee() {
    const navigate = useNavigate();
    const [fileName, setFileName] = useState("No file chosen");

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        setFileName(file ? file.name : "No file chosen");
    };

    const validationSchema = Yup.object({
        firstName: Yup.string().required('First name is required'),
        fatherName: Yup.string().required('Father name is required'),
        lastName: Yup.string().required('Last name is required'),
        date: Yup.date().required('Date of birth is required'),
        pan: Yup.string().matches(/^[A-Z]{5}[0-9]{4}[A-Z]{1}$/, 'Invalid PAN number format').required('PAN number is required'),
        confirmpan: Yup.string().oneOf([Yup.ref('pan'), null], 'PAN numbers must match').required('Confirm PAN number is required'),
        name: Yup.string().required('Name is required'),
        email: Yup.string().email('Invalid email address').required('Email is required'),
        mobileNumber: Yup.string().matches(/^[0-9]{10}$/, 'Invalid mobile number').required('Mobile number is required'),
        state: Yup.string().required('State is required'),
        district: Yup.string().required('District is required'),
        city: Yup.string().required('City is required'),
    });

    const initialValues = {
        firstName: '',
        fatherName: '',
        lastName: '',
        date: '',
        pan: '',
        confirmpan: '',
        name: '',
        email: '',
        mobileNumber: '',
        state: '',
        district: '',
        city: '',
        image: null,
    };

    const onSubmit = (values) => {
        navigate('/addToOrganization')
    };

    const CustomInputField = ({ name, label, type = 'text' }) => (
        <Field name={name}>
            {({ field, meta }) => (
                <Grid item xs={12} sm={6} md={4} lg={3}>
                    <Typography ml={{ xs: "2%", sm: "4%" }} color='#000'>{label}</Typography>
                    <TextField
                         {...field}
                         fullWidth
                         type={type}
                         size='small'
                         focused
                        sx={{
                            '& .MuiInputBase-root': {
                                borderRadius: '15px',
                            },
                            '& .MuiFilledInput-root': {
                                border: '2px solid transparent',
                                borderRadius: '4px',
                                backgroundColor: 'linear-gradient(90deg, #0057ED 0%, #2C5172 100%)', 
                                transition: 'border 0.3s ease',
                            },
                            '& .MuiFilledInput-root.Mui-focused': {
                                border: '2px solid transparent',
                                borderImage: 'linear-gradient(90deg, #0057ED 0%, #2C5172 100%)',
                                borderImageSlice: 1,
                            },
                        }}
                    />
                    {meta.touched && meta.error && (
                        <Typography variant="body2" color="error" ml={{ xs: "2%", sm: "4%" }}>
                            {meta.error}
                        </Typography>
                    )}
                </Grid>
            )}
        </Field>
    );

    return (
        <>
            <Sidebar />
            <Box sx={{ flexGrow: 1, width: '80%', marginLeft: { xs: '15%', sm: '20%' }, marginBottom: { xs: '0', sm: '20%', md: '0' } ,marginTop:{xs:'',sm:'7%'}}}>
                <Grid container >
                    <Grid item>
                        <Typography
                            variant="h4"
                            sx={{
                                textAlign: 'center',
                                background: 'linear-gradient(90deg, #2B4257 0%, #625DBD 100%)',
                                WebkitBackgroundClip: 'text',
                                WebkitTextFillColor: 'transparent',
                                marginRight: '8%'
                            }}
                        >
                            New Employee Details
                        </Typography>
                        <Typography
                            variant="h6"
                            sx={{
                                mt: '2%',
                                textAlign: 'center',
                                background: 'linear-gradient(90deg, #0057ED 0%, #2C5172 100%)',
                                WebkitBackgroundClip: 'text',
                                WebkitTextFillColor: 'transparent',
                                marginRight: '12%'
                            }}
                        >
                            Employee Details
                        </Typography>
                        <Formik
                            initialValues={initialValues}
                            validationSchema={validationSchema}
                            onSubmit={onSubmit}
                        >
                            {({ validateForm }) => (
                                <Form>
                                    <Grid container spacing={2} p={{ xs: 2, md: 4 }} sx={{ width: '100%', maxWidth: '1200px', margin: '0 auto' }} >
                                        <Grid item container spacing={2} gap={{ xs: 0, sm: 4 }}
                                            sx={{
                                                flexDirection: { xs: 'none', sm: 'row', md: 'none' },
                                                flexWrap: { sm: 'nowrap' }
                                            }}>
                                            <CustomInputField name="firstName" label="First Name" />
                                            <CustomInputField name="fatherName" label="Father Name" />
                                            <CustomInputField name="lastName" label="Last Name" />
                                        </Grid>
                                        <Grid item container spacing={2} gap={{ xs: 0, sm: 4 }}
                                            sx={{
                                                flexDirection: { xs: 'none', sm: 'row', md: 'none' },
                                                flexWrap: { sm: 'nowrap' }
                                            }}>
                                            <CustomInputField name="date" label="Date of Birth" type="date" />
                                            <CustomInputField name="pan" label="PAN Number" />
                                            <CustomInputField name="confirmpan" label="Confirm PAN Number" />
                                        </Grid>
                                    </Grid>

                                    <Grid ml={{ xs: 3, sm: 5 }}>
                                        <Typography variant="h5" sx={{ color: '#fff', p: '0.2% 0.3%', textAlign: 'center', width: { xs: '30%', sm: '15%' }, background: 'linear-gradient(90deg, #553EBD 0%, #7A538E 100%)' }}>
                                            Image
                                        </Typography>
                                        <Box sx={{ width: { xs: '90%', sm: '45%', md: '35%' }, height: '50%', padding: '2%', mt: { xs: '5%', sm: '2%' }, display: 'flex', flexDirection: 'row', background: ' rgba(248, 252, 255, 1)', border: '1px dashed #553EBD' }}>
                                            <Button
                                                variant="contained"
                                                component="label"
                                                sx={{
                                                    border: '0.52px solid',
                                                    borderImageSource: 'linear-gradient(90deg, #553EBD 0%, #7A538E 100%)',
                                                    borderImageSlice: 1,
                                                    backgroundColor: 'transparent',
                                                    color: '#553EBD',
                                                    '&:hover': {
                                                        backgroundColor: 'rgba(85, 62, 189, 0.1)',
                                                    },
                                                }}
                                            >
                                                Choose File
                                                <input
                                                    type="file"
                                                    hidden
                                                    onChange={handleFileChange}
                                                />
                                            </Button>
                                            <Typography variant="body2" ml={2} mt={1}>
                                                {fileName}
                                            </Typography>
                                        </Box>
                                    </Grid>

                                    <Grid container spacing={2} p={{ xs: 2, md: 4 }} sx={{ width: '100%', maxWidth: '1200px', margin: '0 auto' }} >
                                        <Typography
                                            variant="h6"
                                            sx={{
                                                mt: 2,
                                                textAlign: 'center',
                                                width: '100%',
                                                background: 'linear-gradient(90deg, #0057ED 0%, #2C5172 100%)',
                                                WebkitBackgroundClip: 'text',
                                                WebkitTextFillColor: 'transparent',
                                                mr: '10%'
                                            }}
                                        >
                                            Address
                                        </Typography>
                                        <Grid container spacing={2} mt={2} gap={{ xs: 0, sm: 4 }}
                                            sx={{
                                                flexDirection: { xs: 'none', sm: 'row', md: 'none' },
                                                flexWrap: { sm: 'nowrap' }
                                            }}>
                                            <CustomInputField name="name" label="Name" />
                                            <CustomInputField name="email" label="Email ID" />
                                            <CustomInputField name="mobileNumber" label="Mobile Number" />
                                        </Grid>
                                        <Grid container spacing={2} mt={0} gap={{ xs: 0, sm: 4 }}
                                            sx={{
                                                flexDirection: { xs: 'none', sm: 'row', md: 'none' },
                                                flexWrap: { sm: 'nowrap' }
                                            }}>
                                            <CustomInputField name="state" label="State" />
                                            <CustomInputField name="district" label="District" />
                                            <CustomInputField name="city" label="City" />
                                        </Grid>
                                    </Grid>

                                    <Grid container mt={2} mb={'2%'} justifyContent="center">
                                        <Grid item xs={10} sm={4}>
                                            <Button
                                                type="submit"
                                                variant="contained"
                                                fullWidth
                                                onClick={async () => {
                                                    const errors = await validateForm();
                                                    if (Object.keys(errors).length === 0) {
                                                        console.log('Form is valid, proceed to next step');
                                                    }
                                                }}
                                                sx={{
                                                    background: 'linear-gradient(90deg, #553EBD 0%, #7A538E 100%)',
                                                    width: { xs: '100%', sm: '75%', md: '50%' },
                                                    mx: 'auto',
                                                }}
                                            >
                                                Next
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Form>
                            )}
                        </Formik>
                    </Grid>
                </Grid>
            </Box>
        </>
    );
}

export default NewEmployee;
