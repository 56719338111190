import React from "react";
import "../../Partnerdetails/Partnerdetails.scss"
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import {
  adminUserRatingDetailsAction, clearAdminUserRatingDetailsAction
} from "../../../store/actions";
import PeopleIcon from "@mui/icons-material/People";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { Typography, Box, Grid, } from "@mui/material";
import Comments from "../../../components/comments";
import OutstandingLoans from "../../../components/outstandings";
import PendingReceivables from "../../../components/pendingReceivables";
import LegalProceedings from "../../../components/legalProceedings";
import Reachability from "../../../components/reachability";
import RecoveryProbability from "../../../components/recoveryProbability";
import { dispatchAll } from "../../../utils";
import { toast } from "react-toastify";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, } from 'recharts';
import Ratings from "../../../components/ratings";

const UserRatingDetails = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();


  const [selectedAssocPartner] = React.useState(
    location.state.selectedAssocPartner
  );

  console.log("selectedAssocPartner", selectedAssocPartner);

  const [partnersRatings, setPartnersRatings] = React.useState([]);
  const [overallDetails, setOverallDetails] = React.useState({
    overAllRatings: {
      1: 0.0,
      2: 0.0,
      3: 0.0,
      4: 0.0,
      5: 0.0,
      6: 0.0,
      7: 0.0,
      8: 0.0,
      9: 0.0,
      10: 0.0,
    },
    totalAmountBalance: 0,
    totalCustomers: 0,
    totalPartners: 0,
    avgRating: 0,
  });
  const { successData: PartnerRatingData, error, errorInfo } = useSelector((state) => state.getAdminUserRatingDetailsReducer) || {};
  const business_partner_assoc_id = selectedAssocPartner;
  const [outStanding, setOutStanding] = React.useState("");
  const [pendingReceivables, setPendingReceivables] = React.useState("");
  const [pendingCount, setPendingCount] = React.useState(0);
  const [totalCustomer, setTotalCustomer] = React.useState(0);
  const [dsoAverageDays, setDsoAverageDays] = React.useState(0);
  const [legalProceedings, setLegalProceedings] = React.useState(0);
  const [reachability, setReachability] = React.useState(0);
  const [avgRating, setAvgRating] = React.useState(0);
  const [reachabilityCustomerCount, setReachabilityCustomerCount] =
    React.useState(0);
  const [comments, setComments] = React.useState([
    {
      heading: "",
      comment: "",
    },
  ]);
  const [recoveryProbability, setRecoveryProbability] = React.useState([
    {
      yes: 0,
      yes_with_multiple_attempts: 0,
      no: 0,
      difficult: 0
    },
  ]);

  React.useEffect(() => {
    return () => dispatchAll([clearAdminUserRatingDetailsAction], dispatch);
  }, [dispatch]);
  React.useEffect(() => {
    dispatch(
      adminUserRatingDetailsAction({
        endPoint: `admin/admin-partner-ratings?user_id=${business_partner_assoc_id}`,
      })
    );
  }, [dispatch, business_partner_assoc_id]);

  React.useEffect(() => {
    if (PartnerRatingData) {
      console.log("PartnerRatingData", PartnerRatingData);
      setComments(PartnerRatingData.all_comments);
      setOutStanding(PartnerRatingData.outstanding);
      setPendingReceivables(PartnerRatingData.pending_receivables);
      setPendingCount(PartnerRatingData.pending_count);
      setTotalCustomer(PartnerRatingData.total_customers);
      setDsoAverageDays(PartnerRatingData.dso_average_days);
      setReachability(PartnerRatingData.reachability_percentage);
      setReachabilityCustomerCount(PartnerRatingData.reachability_customer_count);
      setLegalProceedings(PartnerRatingData.no_of_legal_proceeding);
      setRecoveryProbability(PartnerRatingData.recovery_probability_counts);
      setPartnersRatings(PartnerRatingData.num_of_ratings);
      setOverallDetails(PartnerRatingData.overall_ratings);
      setAvgRating(PartnerRatingData.avg_rating.toFixed(1));
    }
  }, [PartnerRatingData]);

  React.useEffect(() => {
    if (error) {
      toast.success(errorInfo);
    }
  }, [error, errorInfo]);


  const avgRatingdata = PartnerRatingData?.avg_rating_trend;
  // Replace the existing formattedData definition with:
  const formattedData = avgRatingdata?.map(item => ({
    date: new Date(item.date).toLocaleDateString('en-US', {
      month: 'short',
      day: 'numeric',
    }),
    time: new Date(item.date).toLocaleTimeString('en-US', {
      hour: '2-digit',
      minute: '2-digit'
    }),
    fullDateTime: new Date(item.date), // Store full date time for sorting
    avg_score: item.avg_score
  })).sort((a, b) => {
    return b.fullDateTime - a.fullDateTime;  // This will sort by both date and time
  });




  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      return (
        <div style={{ width: "88px", height: "40px", backgroundColor: 'white', padding: '1px', border: '1px solid #ccc', borderRadius: '4px', boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)' }}>
          <p style={{ fontSize: "10px", margin: "1px" }} >{`Date: ${payload[0].payload.date}`}</p>
          <p style={{ fontSize: "10px", margin: "1px" }}>{`Avg Score: ${payload[0].payload.avg_score.toFixed(2)}`}</p>
        </div>
      );
    }
    return null;
  };

  return (
    <Box sx={{ display: "flex" }}>
      <Box component="main" sx={{ flexGrow: "1", p: 3, mt: { xs: 2, md: 2 } }}>
        <Grid container spacing={2} alignItems="center" mb={2}>
          <Grid item xs={12} md={6}>
            <Typography variant="h4" sx={{ fontSize: { xs: "22px", md: "30px" } }}>
              Rating Details of{" "}
              <span className="customer-name">{`${PartnerRatingData?.business_name}`}</span>
            </Typography>
          </Grid>
          <Grid xs={6} sm={6} md={6} lg={6}>
            <div style={{ textAlign: "right" }}>
              <button
                onClick={() => navigate(-1)}
                className="back"
                style={{
                  backgroundColor: "transparent",
                  display: "flex",
                  alignItems: "center",
                  color: "#2b4257",
                  cursor: "pointer",
                  float: "right",
                }}
              >
                <KeyboardBackspaceIcon /> Back
              </button>
            </div>
          </Grid>
        </Grid>

        <Grid container spacing={2} mb={3}>
          {[
            { icon: <PeopleIcon />, text: `No. of Ratings Received : ${PartnerRatingData?.ratings_received}`, className: "cardone" },
            { text: `No. of Ratings Given : ${PartnerRatingData?.ratings_given}`, className: "card2" },
            { text: `Total Number of Disputes : ${PartnerRatingData?.dispute_count}`, className: "card3" }
          ].map((item, index) => (
            <Grid item xs={12} md={4} key={index}>
              <Box className={item.className} sx={{ p: 2, borderRadius: 2 }}>
                {item.icon} {item.text}
              </Box>
            </Grid>
          ))}
        </Grid>

        <Grid container spacing={3}>
          {/* Ratings Section */}
          <Grid sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' } }}>
            <Grid item xs={12} md={5} mt={2}>
              <Grid item xs={12} sm={12} md={6} lg={4} sx={{
                boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.2)",
                borderRadius: "15px",
                margin: "0 auto",
                backgroundColor: "white",
                maxWidth: { xs: "100%", md: "400px" },
                minWidth: { xs: "100%", md: "300px" },
                display: "flex",
                padding: "10px",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                textAlign: "center",
                gap: 2,
              }}
              >
                <Ratings
                  overallDetails={overallDetails}
                  avgRating={avgRating}
                  partnerName={`for ${PartnerRatingData?.business_name}`}
                  partnersRatings={partnersRatings}
                />
              </Grid>
            </Grid>
            {/* Chart and Details Section */}
            <Grid item xs={12} md={8}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Box sx={{ bgcolor: 'background.paper', p: 2, borderRadius: 2, boxShadow: 1, height: 350 }}>
                    {formattedData && formattedData.length > 0 ? (
                      <ResponsiveContainer width="100%" height={300}>
                        <LineChart
                          data={[...formattedData].reverse()}
                          margin={{ top: 20, right: 30, left: 20, bottom: 20 }}
                        >
                          <CartesianGrid strokeDasharray="3 3" />
                          <XAxis
                            dataKey="date"
                            orientation="bottom"

                          />
                          <YAxis
                            domain={[0, 10]}
                            ticks={[0, 5, 10]}
                            orientation="left"
                            yAxisId="left"
                          />
                          <Tooltip content={<CustomTooltip />} />
                          <Line
                            type="monotone"
                            dataKey="avg_score"
                            stroke="#00C49F"
                            strokeWidth={2}
                            dot={{ r: 5, fill: '#00C49F' }}
                            yAxisId="left"
                          />
                        </LineChart>
                      </ResponsiveContainer>

                    ) : (
                      <Typography sx={{
                        textAlign: 'center',
                        fontSize: '16px',
                        color: '#2b4257',
                      }}>
                        No.of Ratings Not Available to Display Graph.
                      </Typography>
                    )}
                  </Box>
                </Grid>

                <Grid item xs={12}>
                  <PendingReceivables pendingReceivables={pendingReceivables} totalCustomer={pendingCount} />
                </Grid>
                <Grid container item spacing={2}>
                  {[
                    <OutstandingLoans outstanding={outStanding} totalCustomer={totalCustomer} pendingCount={pendingCount} dsoAverageDays={dsoAverageDays} />,
                    <RecoveryProbability recoveryProbability={recoveryProbability} />,
                    <LegalProceedings legalProceedings={legalProceedings} />,
                    <Reachability reachability={reachability} customers={reachabilityCustomerCount} />
                  ].map((component, index) => (
                    <Grid item xs={12} sm={6} key={index}>
                      {component}
                    </Grid>
                  ))}
                </Grid>


                {/* Comments Section */}
                <Grid item xs={12}>
                  <Box sx={{ bgcolor: 'background.paper', p: 2, borderRadius: 2, boxShadow: 1 }}>
                    <Comments comments={comments} />
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default UserRatingDetails;