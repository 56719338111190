import React, { useState, useEffect } from 'react';
import { Box, Typography, Button, TextField, Paper, Grid, Alert, IconButton } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { postloginOtpAction, globalConfigAction, loginAction } from '../../store/actions';
import Navbar from '../navbar';
import Termsandpolicy from '../terms_policy';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { Login } from '../login/schmaforloginpagevalidation';
import { useFormik } from "formik";
import HeroSectionImg from '../../assets/HeroSectionImg.png';
import clearbiz3 from '../../assets/clearbiz3.png';

const LoginModule = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { successData, error } = useSelector((state) => state.loginUserDetails) || {};
  const { loading: otpLoading, successData: loginWithOtpData, error: loginWithOtpError, errorInfo } = useSelector((state) => state.loginWithOtpReducer) || {};
  const [showPassword, setShowPassword] = useState(false);
  const [showOTPBox, setShowOTPBox] = useState(false);
  const [otp, setOtp] = useState('');
  const [isOtpSent, setIsOtpSent] = useState(false);
  const [timer, setTimer] = useState(0);
  const [isVerified, setIsVerified] = useState(false);
  const [phoneNumberError, setPhoneNumberError] = useState(false);
  const [otpError, setOtpError] = useState(false);

  const userDetailString = localStorage.getItem("UserDetail");
  const UserDetail = userDetailString ? JSON.parse(userDetailString) : {};

  console.log("otpsent", isOtpSent);
  

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  const handelBackToLogin = () => {
    setShowOTPBox(false);
  };

  useEffect(() => {
    if (UserDetail.key) {
      navigate('/dashboard');
    }
  }, [navigate,UserDetail.key]);

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
  } = useFormik({
    initialValues: {
      pan: '',
      password: '',
      phoneNumber: '',
      otp: '',
    },
    validationSchema: Login,
    onSubmit: (values) => {
      const userDetails = {
        password: values.password,
        pan_number: values.pan,
      };
      dispatch(loginAction(userDetails));
    },
  });

  useEffect(() => {
    if (successData) {
      localStorage.setItem("UserDetail", JSON.stringify(successData.user));
      navigate("/dashboard");
      toast.success(successData.user.is_staff ? "Admin logged in successfully" : "User logged in successfully");
    }
  }, [successData, navigate]);

  useEffect(() => {
    dispatch(globalConfigAction());
  }, [dispatch]);

  useEffect(() => {
    if (error) {
      toast.error("Invalid credentials");
    }
  }, [error]);

  useEffect(() => {
    let interval;
    if (isOtpSent && timer > 0) {
      interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    } else {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [isOtpSent, timer]);

  const handlegetotp = () => {
    const otpDetails = {
      phone_number: values.phoneNumber,
    };
    dispatch(postloginOtpAction(otpDetails));
    setIsOtpSent(true);
    setTimer(120); // Restart timer
    setOtp(''); // Clear the OTP field
    setIsVerified(false); // Reset OTP verification status
  };

  const handleVerifyOtp = () => {
    const otpDetails = {
      phone_number: values.phoneNumber,
      otp: otp,
    };
    setIsVerified(true);
    dispatch(postloginOtpAction(otpDetails));
  };

  useEffect(() => {
    if (loginWithOtpData) {
      if (isOtpSent) {
        // setIsOtpSent(true);
        setTimer(120); // 2 minute timer
        toast.success("OTP sent successfully.");
      } else if (isVerified && timer === 0) {
        setIsOtpSent(false);
      }
    }
  }, [loginWithOtpData, isOtpSent, isVerified]);

  useEffect(() => {
    if (loginWithOtpData && isVerified) {
      localStorage.setItem("UserDetail", JSON.stringify(loginWithOtpData.user));
      navigate("/dashboard");
      toast.success("OTP verified. User logged in successfully");
    }
  }, [loginWithOtpData, isVerified, navigate]);

  useEffect(() => {
    if (loginWithOtpError) {
      toast.error(errorInfo[0]);
    }
  }, [loginWithOtpError, errorInfo]);

  const handleOTPLinkClick = () => {
    setShowOTPBox(true);
  };

  const handlePhoneNumberChange = (e) => {
    const { value, name } = e.target;
    handleChange(e);

    switch (name) {
      case 'phoneNumber': {
        const isValidPhoneNumber = /^\d{10}$/.test(value);
        setPhoneNumberError(!isValidPhoneNumber);
        break;
      }
      case 'otp': {
        setOtp(value);
        const isValidOtp = /^\d{6}$/.test(value);
        setOtpError(!isValidOtp);
        break;
      }
      default:
        break;
    }
  };

  return (
    <>
      <Navbar />
      <Box
        sx={{
          position: 'relative',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          display: 'flex',
          alignItems: 'center',
          minHeight: "90vh",
          marginLeft: { sm: '2%', md: '8%', xs: '8%' },
          marginRight: '8%'
        }}
      >
        <Grid container spacing={2} sx={{ padding: '3rem', width: '150%' }}>
          <Grid item xs={12} sm={6} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
            <Box sx={{
              display: 'flex', flexDirection: 'column', marginRight: '15%', display: { xs: 'none', sm: 'block' }
            }}>
              <img
                src={HeroSectionImg}
                alt="Group53"
                style={{
                  width: '100%',
                  height: 'auto',
                  maxWidth: '100%',

                }}
              />
              <Grid container direction="column" spacing={2} sx={{ width: "100%", margin: '0 auto', marginTop: 1 }}>
                <Grid item>
                  <Typography
                    sx={{
                      fontSize: { xs: '1.2rem', sm: '1.5rem' },
                      color: '#000',
                      marginTop: { xs: '5%', sm: '0%', md: '5%' },
                      fontFamily: 'Times New Roman',
                      textAlign: 'center',
                      display: { xs: 'none', sm: 'block' }

                    }}
                  >
                    Connecting Business For A Better Tomorrow
                  </Typography>
                </Grid>
                {/* <Grid item>
                  <Typography
                    sx={{
                      fontFamily: 'NanumGothic',
                      textAlign: 'center',
                      color: '#2b4257',
                      fontSize: { xs: '1rem', sm: '0.8rem' },
                      display: { xs: 'none', sm: 'block' }
                    }}
                  >
                    Unite your business ecosystem with ClearBiz, the B2B rating platform that strengthens connections between companies, suppliers, distributors, and retailers.
                  </Typography>
                </Grid> */}
              </Grid>
            </Box>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Paper elevation={10} sx={{
              backgroundColor: 'rgba(255, 255, 255, 0.9)',
              padding: { xs: 2, sm: 3 },
              borderRadius: 2,
              marginLeft: { xs: '-30%', sm: '5%', md: '15%' },
              marginRight: { xs: '0%', sm: '15%' },
              marginTop: { xs: '-30%', sm: '0%', md: 'auto' },
              width: { xs: '160%', sm: '120%', md: '80%' }
            }}>
              {!showOTPBox && (
                <>
                  <Box sx={{ display: 'flex', justifyContent: 'center', marginBottom: 3 }}>
                    <img
                      src={clearbiz3}
                      alt="clearbiz"
                      style={{
                        width: { xs: '80%', sm: '50%' },
                        height: 'auto',
                        maxWidth: '60%',
                      }}
                    />
                  </Box>
                  <Typography sx={{ textAlign: 'center', marginBottom: 3, color: '#2b4257', fontSize: { xs: '0.75rem', sm: '0.9rem' } }}>
                    Please enter the details to login to your account
                  </Typography>
                </>
              )}
              {!showOTPBox ? (
                <form onSubmit={handleSubmit}>
                  <TextField
                    fullWidth
                    id="pan"
                    name="pan"
                    label="PAN Number"
                    type={"text"}
                    value={values.pan}
                    onChange={handleChange}
                    onInput={(e) => (e.target.value = ("" + e.target.value).toUpperCase())}
                    onBlur={handleBlur}
                    error={touched.pan && Boolean(errors.pan)}
                    helperText={touched.pan && errors.pan}
                    sx={{ marginBottom: 2 }}
                  />
                  <TextField
                    fullWidth
                    id="password"
                    name="password"
                    label="Password"
                    type={showPassword ? 'text' : 'password'}
                    value={values.password}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.password && Boolean(errors.password)}
                    helperText={touched.password && errors.password}
                    sx={{ marginBottom: 2 }}
                    InputProps={{
                      endAdornment: (
                        <IconButton onClick={handleTogglePassword} edge="end">
                          {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                        </IconButton>
                      ),
                    }}
                  />
                  <Button
                    type="submit"
                    sx={{
                      marginBottom: 1,
                      display: 'block',
                      marginLeft: 'auto',
                      marginRight: 'auto',
                      width: '120px',
                      borderRadius: '20px'
                    }}
                  >
                    Login
                  </Button>
                  <Typography variant="h6" align="center" sx={{ marginBottom: 1 }}>
                    <Link onClick={handleOTPLinkClick} style={{ cursor: 'pointer', color: '#2b4257', marginBottom: 1 }}>
                      Use OTP Login
                    </Link>
                  </Typography>
                </form>
              ) : (
                <Box>
                  <Typography variant='h5' sx={{ fontWeight: 'bold', textAlign: 'center', marginBottom: 2 }}>
                    OTP Verification
                  </Typography>
                  <TextField
                    fullWidth
                    label="Phone Number"
                    name='phoneNumber'
                    variant="outlined"
                    value={values.phoneNumber}
                    onChange={handlePhoneNumberChange}
                    onBlur={handleBlur}
                    disabled={isOtpSent}
                    error={phoneNumberError || (touched.phoneNumber && Boolean(errors.phoneNumber))}
                    helperText={phoneNumberError ? 'Invalid Phone Number' : (touched.phoneNumber && errors.phoneNumber)}
                    sx={{ marginBottom: 2 }}
                  />
                  {!isOtpSent && (
                    <Box justifyContent={'space-between'} display={'flex'} alignItems={"baseline"}>
                      <Button
                        variant="contained"
                        sx={{ mt: 3, background: 'linear-gradient(90deg, #65A3DD 0%, #1584D4 48.6%, #5D8FBD 100%)' }}
                        onClick={handlegetotp}
                        disabled={errors.phoneNumber || otpLoading || phoneNumberError || !values.phoneNumber}
                      >
                        {otpLoading ? "Sending.." : "Send OTP"}
                      </Button>
                      <Button onClick={handelBackToLogin}>Back to login</Button>
                    </Box>
                  )}
                  {isOtpSent && (
                    <>
                        <Button
                          onClick={() => {
                              setIsOtpSent(false);
                              setOtp('');
                              setTimer(0);
                              setIsVerified(false);
                              setPhoneNumberError(false);
                              setOtpError(false);

                              values.phoneNumber = '';
                          }}
                          sx={{
                            minWidth: 'fit-content',
                            backgroundColor: 'transparent',
                            color: 'blue',
                            textDecoration: 'underline',
                            padding: 0,
                            marginTop: 0,
                            '&:hover': {
                              backgroundColor: 'transparent',
                              textDecoration: 'underline',
                            },
                          }}                        
                        >
                          Change Phone Number
                        </Button>
                      
                      <TextField
                        fullWidth
                        label="OTP"
                        name='otp'
                        variant="outlined"
                        value={otp}
                        onChange={handlePhoneNumberChange}
                        margin="normal"
                        error={otpError || (touched.otp && Boolean(errors.otp))}
                        helperText={otpError ? 'Invalid OTP' : (touched.otp && errors.otp)}
                        sx={{ marginBottom: 2 }}
                        onBlur={handleBlur}
                      />
                      {timer <= 0 ? (
                        <Alert severity="error">OTP has expired. Please request a new OTP.</Alert>
                      ) : (
                        <Typography variant="body1" sx={{ mt: 2 }}>
                          OTP expires in: {Math.floor(timer / 60)}:{('0' + (timer % 60)).slice(-2)}
                        </Typography>
                      )}
                      <Box display="flex" justifyContent="space-between" mt={2}>
                        {timer <= 0 ? (
                          <Button variant="contained" sx={{ background: 'linear-gradient(90deg, #65A3DD 0%, #1584D4 48.6%, #5D8FBD 100%)' }} onClick={handlegetotp}>
                            Resend OTP
                          </Button>
                        ) : (
                          <Button variant="contained" sx={{ background: 'linear-gradient(90deg, #65A3DD 0%, #1584D4 48.6%, #5D8FBD 100%)' }} onClick={handleVerifyOtp} disabled={timer <= 0 || otpLoading || !otp || otpError}>
                            Submit
                          </Button>
                        )}
                      </Box>
                    </>
                  )}
                </Box>
              )}
              {!showOTPBox && (
                <>
                  <Grid container justifyContent="center" alignItems="center" spacing={2}>
                    <Grid item xs={12}>
                      <Typography align="center">
                        Don't have an account? <Link to="/register" underline="hover" style={{ color: '#2b4257' }}>Register now!</Link>
                      </Typography>
                    </Grid>
                  </Grid>
                  <Link to="/resetpassword" underline="hover" style={{ display: 'block', textAlign: 'center', marginTop: 4 }}>
                    <Typography style={{ color: "primary" }}>Forgot Password?</Typography>
                  </Link>
                </>
              )}
            </Paper>
          </Grid>

        </Grid>
      </Box>
      <Termsandpolicy />
    </>
  );
};

export default LoginModule;
