import React from "react";
import '../../styles/scoreDetails.css'

import PropTypes from "prop-types"


const RecoveryProbability = ({recoveryProbability}) => {
  return (
    <>
      <div className="styleforheading">Recovery Probability</div>
      <div className="main"> 
        <h5>Yes : {recoveryProbability.yes}</h5>
        <h5>Yes With Multiple Attempts : {recoveryProbability.yes_with_multiple_attempts}</h5>
        <h5>Difficult : {recoveryProbability.difficult}</h5>
        <h5>No : {recoveryProbability.no}</h5>

    </div>
    </>
  );
};

export default RecoveryProbability;

RecoveryProbability.propTypes={
  recoveryProbability: PropTypes.string.isRequired
}
