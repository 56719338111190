import React from "react";
import { Avatar, Box, Typography, Button, Rating, useMediaQuery } from "@mui/material";
import SideBar from "../../../components/sidebar";
import { useNavigate, useLocation } from "react-router-dom";

function EmployeeListCard({ hideButton }) {
  const navigate = useNavigate();
  const location = useLocation();
  const isDetailsPage = location.pathname === '/employeeDetails';
  const isMobile = useMediaQuery("(max-width:600px)");

  const handleViewEdit = () => {
    if (isDetailsPage) {
      navigate('/employeeEdit');
    } else {
      navigate('/employeeDetails');
    }
  };

  return (
    <>
      <SideBar />
      {isMobile ? (
        <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          backgroundColor: "#fff",
          borderRadius: "16px",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
          width: "86%",
          height: "150px", 
          overflow: "hidden",
          margin: "10px auto",
          marginLeft: '13%'
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "flex-start",
            gap: "12px",
            width: "100%",
          }}
        >
          <Avatar
            src=""
            alt="Employee Photo"
            sx={{
              width: "60px",
              height: "60px",
              mt:'15%',
              ml:'2%',
            }}
          />
          <Box sx={{ flex: 1 }}>
            <Typography sx={{ fontWeight: "600", fontSize: "16px", mb: 1 ,ml:'8%'}}>
              Employee Name
            </Typography>
            
            <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: '8px', mb: 2 ,ml:'5%'}}>
              <Box>
                <Typography sx={{ color: "#65A3DD", fontSize: "12px" }}>
                  Designation
                </Typography>
                <Typography fontSize="10px">Full Stack Developer</Typography>
              </Box>
              <Box>
                <Typography sx={{ color: "#65A3DD", fontSize: "12px" }}>
                  Work Period
                </Typography>
                <Typography fontSize="10px">2019-2021</Typography>
              </Box>
            </Box>
            <hr/>
            
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center',ml:'5%' }}>
              <Box>
                <Typography sx={{ color: "#65A3DD", fontSize: "12px", mb: 0.5 }}>
                  Total Rating
                </Typography>
                <Rating value={4} readOnly size="small" />
              </Box>
              
              {!hideButton && (
                <Button
                  variant="contained"
                  onClick={handleViewEdit}
                  sx={{
                    background: "linear-gradient(90deg, #65A3DD 0%, #1584D4 48.6%, #5D8FBD 100%)",
                    color: "#fff",
                    textTransform: "none",
                    borderRadius: "20px",
                    padding: "5px 6px",
                    minWidth: "100px",
                    fontSize: "14px",
                    ":hover": {
                      background: "linear-gradient(90deg, #5D8FBD 0%, #1584D4 48.6%, #65A3DD 100%)"
                    },
                  }}
                >
                  {isDetailsPage ? "Edit" : "View/Edit"}
                </Button>
              )}
            </Box>
          </Box>
        </Box>
      </Box>
      ) : (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            backgroundColor: "#fff",
            padding: "2%",
            borderRadius: "25px",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
            width: 'fit-content',
            minWidth: "70%",
            fontSize: "14px",
            ml: '20%', mb: '1%',
          }}
        >
          <Avatar
            src=''
            alt="Image"
            sx={{
              width: "60px",
              height: "60px",
              marginRight: "15px",
            }}
          />
          <Box>
            <Typography sx={{ fontWeight: "bold" }}>Employee Name</Typography>
          </Box>
          <Box ml="5%">
            <Typography sx={{ background: 'linear-gradient(90deg, #65A3DD 0%, #1584D4 48.6%, #5D8FBD 100%)', WebkitBackgroundClip: 'text', WebkitTextFillColor: 'transparent', cursor: "pointer" }}>
              Designation
            </Typography>
            <Typography>UI/UX Designer</Typography>
          </Box>
          <Box sx={{ textAlign: "center", ml: '5%' }}>
            <Typography sx={{ background: 'linear-gradient(90deg, #65A3DD 0%, #1584D4 48.6%, #5D8FBD 100%)', WebkitBackgroundClip: 'text', WebkitTextFillColor: 'transparent', cursor: "pointer" }}>
              Work Period
            </Typography>
            <Typography>2019-2021</Typography>
          </Box>
          <Box sx={{ textAlign: "center", ml: '5%' }}>
            <Typography sx={{ background: 'linear-gradient(90deg, #65A3DD 0%, #1584D4 48.6%, #5D8FBD 100%)', WebkitBackgroundClip: 'text', WebkitTextFillColor: 'transparent', cursor: "pointer" }}>
              Total Rating
            </Typography>
            <Rating value={5} readOnly size="small" />
          </Box>

          {!hideButton && (
            <Button
              variant="contained"
              onClick={handleViewEdit}
              sx={{
                background: 'linear-gradient(90deg, #65A3DD 0%, #1584D4 48.6%, #5D8FBD 100%)',
                color: "#fff",
                textTransform: "none",
                borderRadius: "20px",
                padding: "5px 15px",
                ml: '5%',
                width: '20%',
                ":hover": {
                  backgroundColor: "#357ABD",
                },
              }}
            >
              {isDetailsPage ? 'Edit' : 'View/Edit'}
            </Button>
          )}
        </Box>
      )}
    </>
  );
}

export default EmployeeListCard;
