import React from 'react'
import { Box } from '@mui/material'
import SideBar from "../../../components/sidebar";
import EmployeeListCard from './EmployeeListCard'


function EmployeeDashboard() {
  return (
    <>
    <SideBar/>
    <Box mt={{xs:'20%',sm:'10%'}} mb={{xs:'30%',sm:'15%'}}>
        <EmployeeListCard/>
        <EmployeeListCard/>
    </Box>
    </>
  )
}

export default EmployeeDashboard