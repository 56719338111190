   
import sliceCreator from '../index.js';
import {ADMIN_INACTIVE_USERS_NOTIFICATION} from "../../../constants"

const _ADMIN_INACTIVE_USERS_NOTIFICATION = sliceCreator('ADMIN_INACTIVE_USERS_NOTIFICATION',ADMIN_INACTIVE_USERS_NOTIFICATION, 'post');

const { asyncAction: postAdminInActiveUserEmailNotification, reducer, clearData: clearPostAdminInActiveUserEmailNotification } = _ADMIN_INACTIVE_USERS_NOTIFICATION

export default reducer;

export {
    postAdminInActiveUserEmailNotification,
    clearPostAdminInActiveUserEmailNotification
}