import React from "react";
import LinearProgress, { linearProgressClasses } from "@mui/material/LinearProgress";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import DropdownItems from "../../utils/dropdownutils";
import PropTypes from "prop-types";
import ReactSpeedometer from "react-d3-speedometer";
import "react-circular-progressbar/dist/styles.css";

const SpeedometerWrapper = styled('div')(({ theme }) => ({
  position: 'relative',
  width: '100%',
  maxWidth: '500px',
  margin: '0 auto',
  textAlign: 'center',
  overflow: 'visible',
  '& .gradient-overlay': {
    position: 'absolute',
    width: '217px',
    height: '211px',
    top: '65%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: '50%',
    background: 'linear-gradient(to right, #FF0000 0%, #FF0000 40%, #FFA500 50%, #FFFF00 70%, #00FF00 100%)',
    opacity: 0.4,
    zIndex: 900,
    clipPath: 'path("M 0 0 L 220 0 L 220 110 Q 110 69, 0 110 Z")',
  },
  '& .rating-circle': {
    backgroundColor: '#fff',
    borderRadius: '50%',
    width: '80px',
    height: '85px',
    zIndex: 1001,
    position: 'absolute',
    top: '65%',
    left: '50%',
    transform: 'translate(-50%, -60%)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.4)'
  },
  '@media (max-width: 1024px)': {
    '& .gradient-overlay': {
      width: '220px',
      height: '209px',
    }
  },
  '@media (max-width: 768px)': {
    '& .gradient-overlay': {
      width: '217px',
      height: '216px',
    },
    '& .rating-circle': {
      width: '70px',
      height: '70px',
    }
  },

  '@media screen and (width: 820px)': {
    '& .gradient-overlay': {
      width: '219px',
      height: '224px',
      transform: 'translate(-50%, -50%) scale(0.95)',
    },
    '& .rating-circle': {
      width: '65px',
      height: '65px',
      transform: 'translate(-50%, -60%) scale(0.95)',
    },
    '& .speedometer-container': {
      transform: 'scale(0.95)',
    },
  },
  '@media (max-width: 480px)': {
    '& .gradient-overlay': {
      width: '218px',
      height: '213px',
    },
    '& .rating-circle': {
      width: '60px',
      height: '60px',
    }
  },
  '@media (max-width: 320px)': {
    '& .gradient-overlay': {
      width: '250px',
      height: '300px',
    },
    '& .rating-circle': {
      width: '50px',
      height: '50px',
    }
  }
}));


const BorderLinearProgress = styled(LinearProgress)(({ theme,score }) => ({
  height: 6,
  borderRadius: 10,
  transition: 'all 0.6s ease-in-out',
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
    transition: 'background-color 0.6s ease-in-out',
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: score.value >= 7.5
      ? "#38DA1E"
      : score.value >= 5
      ? "#F1C21B"
      : "#E42727",
    transition: 'transform 0.8s cubic-bezier(0.4, 0, 0.2, 1)',
  },
}));

const Ratings = ({ overallDetails, partnerName, partnersRatings,avgRating }) => {
  const getRatingLabels = () => {
    let rLbls = [];
    const validfield = DropdownItems.score.slice(0, 10);

    rLbls = validfield.map((score, i) => {
      const ratingValue = overallDetails[validfield.length - i];
      if (ratingValue > 0) {
        return (
          <div key={score.value}>
            <Typography className="bold" style={{
              marginBottom: "4px",
              marginTop: "12px",
              fontWeight: "bold",
              fontSize: "16px",
            }}>
              {score.label}: {ratingValue}
            </Typography>
            <BorderLinearProgress
              variant="determinate"
              value={(ratingValue / partnersRatings) * 100}
              score={score}
            />
          </div>
        );
      }
      return null;
    });

    return rLbls;
  };

  return (
    <>
      <div style={{ transition: 'all 0.3s ease' }}>
        <Typography className="com" style={{ fontSize: "18px", color: "#000", transition: 'all 0.3s ease' }}>
          <b>{`Overall Rating ${partnerName}`}</b>
        </Typography>

        <SpeedometerWrapper>
          <div className="gradient-overlay" />
          <div className="rating-circle">
            <div>
              <h2 style={{ margin: 0, fontSize: 'clamp(16px, 4vw, 24px)' }}>
                <b>{`${avgRating || 0}`}</b>
              </h2>
            </div>
          </div>

          <ReactSpeedometer
            width={300}
            height={250}
            containerClassName="speedometer-container"
            value={avgRating || 0}
            minValue={0}
            maxValue={10}
            needleColor="black"
            segments={39}
            needleHeightRatio={0.7}
            ringWidth={10}
            needleTransition="easeElastic"
            needleTransitionDuration={3000}
            valueTextFontSize="28px"
            textColor="#fff"
            paddingHorizontal={5}
            paddingVertical={5}
            valueBox={{
              background: 'linear-gradient(180deg, rgba(255,255,255,0.8) 0%, rgba(255,255,255,0.3) 100%)',
              boxShadow: 'inset 0 4px 8px rgba(255,255,255,0.9), inset 0 -4px 8px rgba(0,0,0,0.1)',
              borderRadius: '50%',
            }}
            containerStyle={{
              background: 'linear-gradient(180deg, rgba(255,255,255,0.2) 0%, transparent 50%)',
              borderRadius: '50%',
            }}
            customSegmentStops={[
              0, 0.25, 0.5, 0.75, 1, 1.25, 1.5, 1.75, 2, 2.25, 2.5, 2.75, 3, 3.25, 3.5, 3.75, 4, 4.25, 4.5, 4.75, 5, 5.25, 5.5, 5.75, 6, 6.25, 6.5, 6.75, 7, 7.25, 7.5, 7.75, 8, 8.25, 8.5, 8.75, 9, 9.5, 9.75, 10,
            ]}
            segmentColors={[
              "#FF0000", "#FF0800", "#FF1000", "#FF1800", "#FF2000",
              "#FF2800", "#FF3000", "#FF3800", "#FF4000", "#FF4800",
              "#FF5000", "#FF5800", "#FF6000", "#FF6800", "#FF7000",
              "#FF7800", "#FF8000", "#FF8800", "#FF9000", "#FF9800",
              "#FFA000", "#FFA800", "#FFB000", "#FFB800", "#FFC000",
              "#FFC800", "#FFD000", "#FFD800", "#FFE000", "#FFE800",
              "#FFFF00", "#EAFF00", "#D4FF00", "#BEFF00", "#A8FF00",
              "#92FF00", "#7CFF00", "#66FF00", "#50FF00", "#3AFF00",
            ]}
            customSegmentLabels={Array(39).fill({ text: "", position: "OUTSIDE" })}
            valueTextFontWeight="bold"
            fluidWidth={false}
            startColor="transparent"
            endColor="transparent"
            labelFontSize="12px"
          />
          <Typography className="riv" style={{ marginTop: '-70px', position: 'relative', zIndex: 1000 }}>
            <span style={{
              fontWeight: "bold",
              fontSize: "clamp(14px, 3vw, 18px)",
              backgroundColor: "#2b4257",
              borderRadius: "20px",
              padding: "5px 10px",
              color: "white"
            }}>
              Total: {partnersRatings.length} Ratings
            </span>
          </Typography>
        </SpeedometerWrapper>

        <div style={{ marginTop: "13px", marginLeft: "10px", marginRight: "10px", borderRadius: "5px", padding: "5px" }}>
          {getRatingLabels()}
        </div>
      </div>
    </>
  );
};

Ratings.propTypes = {
  overallDetails: PropTypes.object,
  partnerName: PropTypes.any,
  partnersRatings: PropTypes.array,
};

export default Ratings;