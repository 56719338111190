import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import GlobalStyles from "@mui/material/GlobalStyles";
import Grid from "@mui/material/Grid";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import logo from "../../assets/clearbiz2.png";
import PaymentReview from "../../assets/PaymentReview.png";
import { getSubscriptionAction } from "../../store/actions";
import Termsandpolicy from "../terms_policy";
import PropTypes from "prop-types";
const defaultTheme = createTheme();

function SubscriptionCard({
  item,
  getcardBackgroundColor,
  index,
  isSubscriptionActive,
  handleButtonClick,
  getButtonBackgroundColor,
}) {
  return (
    <Card
      sx={{
        display: "flex",
        flexDirection: "column",
        minHeight: "90%",
        borderRadius: "10px",
        boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
        border: "1px solid #e0e0e0",
        overflow: "hidden",
        transition: "transform 0.2s ease-in-out",
        marginTop: "-10%",
        "&:hover": {
          transform: "scale(1.05)",
        },
      }}
    >
      <CardHeader
        title={`${item.type}`}
        titleTypographyProps={{
          align: "center",
          sx: {
            fontSize: "1.5rem",
            fontWeight: "bold",
            background: "linear-gradient(90deg, #0057ED 0%, #2C5172 100%)", // Same gradient as the button
            WebkitBackgroundClip: "text",
            WebkitTextFillColor: "transparent",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          },
        }}
      />
        <Typography sx={{fontSize: "1rem",fontWeight: "bold",textAlign:'center',marginLeft:'2%',}}>
          {`${item.name}`}</Typography>
      <CardContent
        sx={{
          flexGrow: 1,
          textAlign: "center",
          padding: "16px",
          backgroundColor: "#fff",
        }}
      >
        <Typography
        component="h2"
        variant="h5"
        sx={{
          textDecoration: "line-through",
          color: "#888888",
        }}
      >
        {`₹${item.price}/year`}
      </Typography>
      <Typography
        component="p"
        variant="subtitle1"
        color="#d90429"
        sx={{ fontWeight: "bold", mt: 1 }}
      >
        {`Save ${Math.round(((item.price - item.offer_price) / item.price) * 100)}% on the first-year subscription`}
      </Typography>
        <Typography
          component="h2"
          variant="h4"
          sx={{
            fontWeight: "bold",
            fontSize: "1.5rem",
            mb: 1,
            background: "linear-gradient(90deg, #0057ED 0%, #2C5172 100%)", // Same gradient as button
            WebkitBackgroundClip: "text",
            WebkitTextFillColor: "transparent",
          }}
        >
          {`₹${item.offer_price}/year`}
        </Typography>
        <div style={{ height: "150px", marginTop: "10px" }}>
          <ul style={{ listStyle: "none", padding: 0, marginBottom: "20px" }}>
            {item.description.map((descItem) => (
              <li
                key={descItem}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginBottom: "8px",
                }}
              >
                <Typography component="span" sx={{ color: "#34a0a4", mr: 1 }}>
                  ✓
                </Typography>
                {descItem}
              </li>
            ))}
          </ul>
        </div>
        <Button
          variant="contained"
          sx={{
            backgroundColor: "#274c77",
            width: "50%",
            py: 1.2,
            fontSize: "1rem",
            fontWeight: "bold",
            color: "#ffffff",
            background: "linear-gradient(90deg, #0057ED 0%, #2C5172 100%)",
            borderRadius: "20px",
            "&:hover": {
              background: "linear-gradient(90deg, #2C5172 0%, #0057ED 100%)", // Inverted gradient on hover
            },
          }}
          onClick={() =>
            handleButtonClick(item.offer_price, item.id, item.type)
          }
          disabled={isSubscriptionActive === true}
        >
          {item.display_text}
        </Button>
      </CardContent>
    </Card>
  );
}

export default function Pricing() {
  const [is_company, setIsCompany] = React.useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { successData: subscriptionData } =
    useSelector((state) => state.getSubscriptionData) || {};

  //sort out the array of subscription data with id
  const sortedSubscriptionData = [...subscriptionData?.result || []].sort((a, b) => a.id - b.id);
  console.log("sortedSubscriptionData", sortedSubscriptionData);

    const userDetailString = localStorage.getItem("UserDetail");
    const userDetail = userDetailString ? JSON.parse(userDetailString) : {};

  const handleButtonClick = (price, id, type) => {
    localStorage.setItem("paymentId", id);
    localStorage.setItem("offer_Prize", price);
    localStorage.setItem("subscription_type", type);
    navigate("/payment");
  };

  useEffect(() => {
    dispatch(getSubscriptionAction());
    setIsCompany(localStorage.getItem("is_company"));
  }, [dispatch]);
  
  const is_payment_pending = userDetail?.is_payment_pending;
  const getcardBackgroundColor = (index) => {
    switch (index) {
      case 0:
        return "#a3cef1";
      case 1:
        return "#34a0a4";
      default:
        return "#3a9bdc";
    }
  };

  const getButtonBackgroundColor = (index) => {
    switch (index) {
      case 0:
        return "#274c77";
      case 1:
        return "#38b000";
      default:
        return "#274c77";
    }
  };

  function hadelSignOutClick() {
    localStorage.clear();
    navigate("/");
    window.location.reload();
  }

  return (
    <ThemeProvider theme={defaultTheme}>
      <GlobalStyles
        styles={{ ul: { margin: 0, padding: 0, listStyle: "none" } }}
      />
      <CssBaseline />
      <AppBar
        position="static"
        color="default"
        elevation={0}
        sx={{
          borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
          backgroundColor: "#0065AB",
        }}
      >
        <Toolbar sx={{ flexWrap: "wrap" }}>
          <img
            src={logo}
            alt="ClearBiz Logo"
            style={{ height: "50px", marginRight: "16px" }}
          />
          <nav></nav>
        </Toolbar>
      </AppBar>
      {is_payment_pending === true ? (
        <Grid container xs={"lg"} justifyContent={"center"}>
          <Grid>
            <div style={{ position: "absolute", top: 75, right: 0 }}>
              <div className="back">
                <button
                  style={{
                    backgroundColor: "transparent",
                    border: "none",
                    color: "#012970",
                    fontWeight: "bold",
                  }}
                  type="button"
                  onClick={hadelSignOutClick}
                >
                  SignOut
                </button>
              </div>
            </div>
          </Grid>
          <Grid item mx={4} mt={1}>
            <Box sx={{ marginLeft: "30%" }}>
              <img src={PaymentReview} alt="payment-review" width="70%" />
            </Box>
            <h1
              style={{
                fontWeight: "bold",
                background:
                  "linear-gradient(90deg, #653A9D 20.23%, #9F8CD3 52.69%, #5D8FBD 82.56%)",
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
              }}
              className="text"
            >
              Your Account And Payment Is In Review
            </h1>
          </Grid>
          <Grid
            mx={15}
            sx={{
              fontFamily: "Inter",
              fontSize: "1.5rem",
              textAlign: "center",
              marginBottom: "5%",
            }}
          >
            Thank you for your payment! We are currently verifying your details.
            Please allow some time for this process. You will receive an
            activation email once your verification is complete.
          </Grid>
        </Grid>
      ) : (
        <>
          <Container
            disableGutters
            maxWidth="sm"
            component="main"
            sx={{ pt: 2, pb: 0 }}
          >
            <Typography
              component="h1"
              variant="h4"
              align="center"
              sx={{
                background: "linear-gradient(90deg, #0057ED 0%, #2C5172 100%)", // Same gradient as the button
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
                marginTop:{xs:'15%',sm:'0%'}
              }}
            >
              Discover the Benefits of Our Plans
            </Typography>

            <Grid>
              <div style={{ position: "absolute", top: 75, right: 0 }}>
                <div className="back">
                  <button
                    style={{
                      backgroundColor: "transparent",
                      border: "none",
                      color: "#012970",
                      fontWeight: "bold",
                    }}
                    type="button"
                    onClick={hadelSignOutClick}
                  >
                    SignOut
                  </button>
                </div>
              </div>
            </Grid>

            {/* <Typography
              variant="subtitle1"
              align="center"
              color="error" // Set text color to red
              sx={{
                animation: "flash 1s infinite", // Add the flash animation
                "@keyframes flash": {
                  "0%": { opacity: 1 },
                  "50%": { opacity: 0 },
                  "100%": { opacity: 1 },
                },
              }}
            >
              {isSubscriptionActive !== "true"
                ? "***  Alert: Offer valid only for the first 500 Users ***"
                : "*** subscription is in process ****"}
            </Typography> */}
          </Container>
          <Container maxWidth="lg" component="main" sx={{ mb: 2 }}>
            <Grid container spacing={5} justifyContent={"center"}>
              {sortedSubscriptionData?.
                filter((item) => item.is_active)
                .map((item, index) =>
                  item.type === "CB Pro" && is_company === true ? null : (
                    <Grid item xs={12} sm={6} md={4} key={item.id} marginLeft={{xs:'-10%',sm:'0%',md:'0%'}}>
                      <SubscriptionCard
                        item={item}
                        index={index}
                        handleButtonClick={handleButtonClick}
                        isSubscriptionActive={is_payment_pending}
                        getcardBackgroundColor={getcardBackgroundColor}
                        getButtonBackgroundColor={getButtonBackgroundColor}
                      />
                    </Grid>
                  )
                )}
            </Grid>
          </Container>
          <Termsandpolicy />
        </>
      )}
    </ThemeProvider>
  );
}

SubscriptionCard.propTypes = {
  item: PropTypes.object.isRequired,
  getcardBackgroundColor: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  isSubscriptionActive: PropTypes.string.isRequired,
  handleButtonClick: PropTypes.func.isRequired,
  getButtonBackgroundColor: PropTypes.func.isRequired,
};
