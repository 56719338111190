import sliceCreator from '../index.js';
import {Edit_given_Rating} from "../../../constants/index.js"

const _editGivenRatingAction = sliceCreator('Edit_given_Rating', Edit_given_Rating, 'patch');

const { asyncAction: editGivenRatingAction, reducer, clearData: clearEditGivenRatingAction } = _editGivenRatingAction;

export default reducer;

export {
    editGivenRatingAction,
    clearEditGivenRatingAction
}