import React from 'react'
import Sidebar from '../../../components/sidebar'
import EmployeeListCard from '../EmployeeList/EmployeeListCard'
import {Box,Button} from '@mui/material'
import { useNavigate } from 'react-router-dom'

function AddToOrganization() {
  const navigate = useNavigate();
  return (
    <>
    <Sidebar/>
    <Box textAlign='center' justifyContent='center' alignItems='center' minHeight={'70vh'}>
        <Box 
          mt={{sm:'10%',xs:'30%'}} 
          sx={{
            marginLeft:{sm:'15%',md:'15%',xs: '5%'},
            width:'fit-content',
            minWidth:'70%',
          }}
        >
          <EmployeeListCard hideButton={true} />
          <Button 
          onClick={()=>{navigate('/employeeDashboard')}}
           sx={{
            background: 'linear-gradient(90deg, #65A3DD 0%, #1584D4 48.6%, #5D8FBD 100%)',
            color: 'white',
            borderRadius: '15px',
            mt:'2%',ml:'10%'
          }}>Add to Organization</Button>
        </Box>
    </Box>
    </>
  )
}

export default AddToOrganization