import React, { useEffect, useState } from "react";
import TableData from "../../../utils/tableutils";
import Table from "../../../components/table";
import { Modal } from "react-bootstrap";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import TextField from "@mui/material/TextField";
import "../../Raisedispute/raise.scss";

const DisputeContent = ({
  loadingDisputeData,
  isSelectedResolvedDispute,
  raisedByYou,
  raisedOnYou,
  resolvedDisputeColumns,
  columns,
  onClickEditDetails
}) => {
  if (loadingDisputeData) {
    return <div>Loading...</div>; // You can replace this with a Loader component if you have one
  }

  const disputesToShow = isSelectedResolvedDispute ? raisedOnYou : raisedByYou;

  return disputesToShow.length > 0 ? (
    <Table
      columns={isSelectedResolvedDispute ? resolvedDisputeColumns : columns}
      data={disputesToShow}
      onClickEditDetails={onClickEditDetails}
    />
  ) : (
    <h3 style={{ fontSize: '1.5rem' }}>No Disputes Found</h3>
  );
};

const Disputes = ({ data, loadingDisputeData }) => {
  const [raisedByYou, setRaisedByYou] = useState(data?.raised_by || []);
  const [raisedOnYou, setRaisedOnYou] = useState(data?.raised_on || []);
  const [isSelectedResolvedDispute, setIsSelectedResolvedDispute] = useState(false);
  const [selectedRaisedUsername, setSelectedRaisedUsername] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [reasonText, setReasonText] = useState("");

  const columns = React.useMemo(() => TableData.raisedByYou, []);
  const resolvedDisputeColumns = React.useMemo(() => TableData.raisedOnYou, []);

  useEffect(() => {
    console.log("Disputes:", data);
    setRaisedByYou(data?.raised_by || []);
    setRaisedOnYou(data?.raised_on || []);
  }, [data]);

  const onClickEditDetails = (rowIndex) => {
    const currentDisputes = isSelectedResolvedDispute ? raisedOnYou : raisedByYou;
    const selectedDispute = currentDisputes[rowIndex];
    const selectedRaisedUsername = selectedDispute?.user?.first_name || "";
    setSelectedIndex(rowIndex);
    setSelectedRaisedUsername(selectedRaisedUsername);
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
    setSelectedIndex(null);
    setReasonText("");
  };

  const onSubmitResolvedData = () => {
    if (!reasonText) {
      alert("Please enter a reason before submitting.");
      return;
    }

    const updatedDisputes = [...raisedOnYou];
    updatedDisputes[selectedIndex] = {
      ...updatedDisputes[selectedIndex],
      reason: reasonText,
    };
    setRaisedOnYou(updatedDisputes);
    closeModal();
  };

  return (
    <Box sx={{ flexGrow: 1, p: 3 }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Tabs
            value={isSelectedResolvedDispute ? 1 : 0}
            onChange={(event, newValue) => setIsSelectedResolvedDispute(newValue === 1)}
            indicatorColor="primary"
            textColor="primary"
          >
            <Tab label={`Raised By You (${raisedByYou.length})`} />
            <Tab label={`Raised On You (${raisedOnYou.length})`} />
          </Tabs>
        </Grid>
        <Grid item xs={12}>
          <DisputeContent
            loadingDisputeData={loadingDisputeData}
            isSelectedResolvedDispute={isSelectedResolvedDispute}
            raisedByYou={raisedByYou}
            raisedOnYou={raisedOnYou}
            resolvedDisputeColumns={resolvedDisputeColumns}
            columns={columns}
            onClickEditDetails={onClickEditDetails}
          />
        </Grid>
      </Grid>

      <Modal show={showModal} onHide={closeModal}>
        <Modal.Header closeButton>
          <Modal.Title>Resolve Dispute of <strong>{selectedRaisedUsername}</strong></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <TextField
            label="Reason"
            multiline
            rows={6}
            fullWidth
            value={reasonText}
            onChange={(e) => setReasonText(e.target.value)}
          />
          <div style={{ marginTop: '10px', textAlign: 'right' }}>
            <button className="btn btn-primary" onClick={onSubmitResolvedData}>Submit</button>
          </div>
        </Modal.Body>
      </Modal>
    </Box>
  );
};

export default Disputes;
