import sliceCreator from '../index.js';
import {ADMIN_USER_RATINGS_DETAILS} from "../../../constants/index.js"

const _adminUserRatingDetails = sliceCreator('ADMIN_USER_RATINGS_DETAILS', ADMIN_USER_RATINGS_DETAILS, 'get');

const { asyncAction: adminUserRatingDetailsAction, reducer, clearData: clearAdminUserRatingDetailsAction } = _adminUserRatingDetails;

export default reducer;

export {
    adminUserRatingDetailsAction,
    clearAdminUserRatingDetailsAction
}