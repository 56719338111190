import React from 'react';
import { Link } from 'react-router-dom';

const PageNotFound = () => {
  const styles = {
    container: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100vh',
      textAlign: 'center',
    },
    heading: {
      fontSize: '6em',
      margin: '0.2em 0',
    },
    subheading: {
      fontSize: '2em',
      margin: '0.2em 0',
    },
    paragraph: {
      fontSize: '1.2em',
      margin: '0.2em 0',
    },
    link: {
      padding: '0.5em 1em',
      fontSize: '1em',
      color: '#fff',
      backgroundColor: '#007bff',
      textDecoration: 'none',
      borderRadius: '0.3em',
      marginTop: '1em',
    }
  };

  return (
    <div style={styles.container}>
      <h1 style={styles.heading}>404</h1>
      <h2 style={styles.subheading}>Page Not Found</h2>
      <p style={styles.paragraph}>Opps, the page you are looking for does not exist.</p>
      <Link to="/" style={styles.link}>Go to Home</Link>
    </div>
  );
};

export default PageNotFound;
