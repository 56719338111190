import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import NotificationsIcon from "@mui/icons-material/Notifications";
import SearchIcon from "@mui/icons-material/Search";
import MuiAppBar from "@mui/material/AppBar";
import Badge from "@mui/material/Badge";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import MuiDrawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import InputBase from "@mui/material/InputBase";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Popover from "@mui/material/Popover";
import Toolbar from "@mui/material/Toolbar";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { alpha, styled, useTheme } from "@mui/material/styles";
import React, { useState,useEffect,useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, Link, useLocation } from "react-router-dom";
import clearbiz3 from "../../assets/clearbiz3.png";
import { navList } from "../../constants";
import {
  clearLoginUserData,
  clearSearchPartnerActionData,
  getMyNotificationsAction,
  searchPartnerAction,
  putNotificationsAction,
} from "../../store/actions";

import { dispatchAll } from "../../utils";
import NotificationDropdown from "../notification";
import "./sidebar.scss";
import { toast } from "react-toastify";
import { useMediaQuery } from "@mui/material";
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import MenuIcon from '@mui/icons-material/Menu';
import { AccountCircle } from "@mui/icons-material";
import { employeeNavList } from "../../constants/employeeNavlist";


const drawerWidth = 150;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const StyledTooltip = styled(Tooltip)(({ theme }) => ({
  fontSize: theme.typography.body2.fontSize,
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "#2b4257",
  backgroundColor:'rgba(201, 210, 229, 0.93)',
  borderRadius: '10px',
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 0, 1, 1),
    // vertical padding + font size from searchIcon
    '&::placeholder': {
      color: '#2b4257', // Customize the color as needed
      opacity: 1, // Ensure the opacity is set to 1 for full intensity
    },
    paddingRight: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "25ch",
    },

  },
}));
const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  top: 0,
  right: 0,
  color: "#2b4257",
}));

const CustomTypography = styled(Typography)(({ theme }) => ({
  fontFamily: "Tahoma, sans-serif",
  fontWeight: "bold",
  fontSize: "1.5rem",
  marginRight: theme.spacing(2), 
  [theme.breakpoints.down('sm')]: {
    marginRight: theme.spacing(1), 
    marginLeft: theme.spacing(1),  
  },
}));


const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2)',
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2)',
  [theme.breakpoints.down('sm')]: {
    width: `calc(${theme.spacing(7)} + 1px)`,
    '& .MuiDrawer-paper': {
      width: `calc(${theme.spacing(7)} + 1px)`,
      overflowX: "hidden"
    },
    '& .MuiListItemText-root': {
      display: 'none'
    }
  },
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));


const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  
}));

export default function MiniDrawer() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();
  const [open, setOpen] = React.useState(true);
  const [openDialog, setOpenDialog] = useState(false);
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [subscriptionType, setSubscriptionType] = useState();
  const [newNotificationsCount, setNewNotificationsCount] = useState(0);
  const [isDisabled, setIsDisabled] = useState(false);

  const userDetailString = localStorage.getItem("UserDetail");
  const userDetail = userDetailString ? JSON.parse(userDetailString) : {};

  useEffect(() => {
    setOpen(!isSmallScreen);
  }, [isSmallScreen]);

  const { successData: getNotificationData } =
    useSelector((state) => state.getMyNotificationsAction) || {};
  const { successData: putNotificationsActionData } =
    useSelector((state) => state.putNotificationsAction) || {};
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const [searchValue, setSearchValue] = React.useState("");
  const {
    successData: searchData,
    error: searchError,
    errorInfo: searchErrorInfo,
  } = useSelector((state) => state.searchPartnersData);
  const [notificationAnchorEl, setNotificationAnchorEl] = useState(null);

  const { successData: updateNotificationData } =
    useSelector((state) => state.updateNotificationData) || {};

  const notificationsData = React.useMemo(() => {
    if (getNotificationData) {
      return getNotificationData;
    }
    return [];
  }, [getNotificationData]);



  React.useEffect(() => {
    if (updateNotificationData) {
      dispatch(updateNotificationData({ endpoint: `notifications/` }));
    }
  }, [updateNotificationData, dispatch]);

  const handleNotificationClose = () => {
    setNotificationAnchorEl(null);
  };

  const openNotification = Boolean(notificationAnchorEl);
  const dummyNotifications = [];

  const textStyleUnread = {
    fontWeight: "bold",
    fontSize: "15px",
    color: "red",
  };

  const textStyleRead = {
    fontWeight: "normal",
    fontSize: "15px",
    color: "green",
  };

  React.useEffect(() => {
    dispatch(getMyNotificationsAction());
  }, [dispatch]);


  React.useEffect(() => {
    if (Array.isArray(searchData) && searchData.length === 0) {
      navigate("/noresultssearch");
      dispatch(clearSearchPartnerActionData());
    } else if (searchData) {
      const data = Array.isArray(searchData) ? searchData[0] : searchData;
      // if is_user_exists is true, then it is same as PAN response so setting is_from_gst to false
      const is_from_gst =
        Array.isArray(searchData) && data.is_user_exists
          ? false
          : searchValue.length === 15;
      navigate("/searchprofile", {
        state: { ...data, is_from_gst },
        replace: true,
      });
      dispatch(clearSearchPartnerActionData());
    }
  }, [searchData, dispatch, navigate, searchValue.length]);
  React.useEffect(() => {
    if (searchError) {
      if (searchErrorInfo === "User does not exist. Please register.") {
        toast.error(searchErrorInfo);
      } else {
        navigate("/dashboard");
        toast.error(searchErrorInfo);
      }
      dispatch(clearSearchPartnerActionData());
    }
  }, [searchError, searchErrorInfo, dispatch, navigate]);
  React.useEffect(() => {
    return () => dispatchAll([clearSearchPartnerActionData], dispatch);
  }, [dispatch]);
  const onSearchClick = () => {
    

    if (searchValue.length === 15||searchValue.length === 10) {
      dispatch(
        searchPartnerAction({
          endPoint: `searchPartner/?searchKey=${searchValue}`,
        })
      );
    }
    else(toast.error("Invalid PAN number or GST number."));
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };


  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const onClickLogout = () => {
    dispatchAll([clearLoginUserData], dispatch);
    localStorage.removeItem("UserDetail");
    navigate("/");
  };


  const logoutTimeoutRef = useRef(null);

  const resetLogoutTimer = () => {
    if (logoutTimeoutRef.current) {
      clearTimeout(logoutTimeoutRef.current);
    }
    const currentTime = Date.now();
    localStorage.setItem('lastActivityTime', currentTime);
    logoutTimeoutRef.current = setTimeout(() => {
      onClickLogout();
    }, 43200000); // 12 hour of inactivity
  };
  
  const checkInactivity = () => {
    const lastActivityTime = localStorage.getItem('lastActivityTime');
    if (lastActivityTime) {
      const currentTime = Date.now();
      const timeSinceLastActivity = currentTime - parseInt(lastActivityTime, 10);
      if (timeSinceLastActivity > 43200000) {
        onClickLogout();
      } else {
        resetLogoutTimer();
      }
    } else {
      resetLogoutTimer(); // Initialize the timer if no activity time is stored
    }
  };
  
  const handleVisibilityChange = () => {
    if (document.visibilityState === 'visible') {
      checkInactivity();
    }
  };
  
  useEffect(() => {
    const events = ["mousemove", "keydown", "scroll", "click"];
    const handleEvent = () => resetLogoutTimer();
  
    events.forEach((event) => window.addEventListener(event, handleEvent));
    document.addEventListener('visibilitychange', handleVisibilityChange);
    
    // Check for inactivity on component mount
    checkInactivity();
  
    return () => {
      events.forEach((event) => window.removeEventListener(event, handleEvent));
      document.removeEventListener('visibilitychange', handleVisibilityChange);
      if (logoutTimeoutRef.current) {
        clearTimeout(logoutTimeoutRef.current);
      }
      localStorage.removeItem('lastActivityTime'); // Clear stored time on logout
    };
  }, []);
  

  const onClickSideMenuItem = (item) => {
    navigate(item.goTo);
  };

  useEffect(() => {
    const subscriptionType = userDetail.premium_type;
    if (subscriptionType) {
      try {
        setSubscriptionType(subscriptionType);
      } catch (error) {
        console.error("Failed to parse subscriptionType from localStorage:", error);
      }
    }
    console.log("subscriptionType:", subscriptionType);
  }, []);

  useEffect(() => {
    if (notificationsData.length > 0) {
      const newNotifications = notificationsData.filter(
        (notification) => !notification.is_read
      );
      setNewNotificationsCount(newNotifications.length);
    }
  }, [notificationsData]);

  const handleNotificationBadge = () => {
    // Mark all notifications as read
    notificationsData.forEach((notification) => {
      if (!notification.is_read) {
        dispatch(putNotificationsAction({ endPoint: `notifications/${notification.id}/` }));
      }
    });
  
    // Reset new notifications count
    setNewNotificationsCount(0);
    setNotificationAnchorEl(null);
  }; 

  useEffect(() => {
    const planData = userDetail.is_plan_expired;
    
    const parsedPlanData = planData ? planData : null;
    
    setIsDisabled(parsedPlanData);
  }, []);
  
  return (
    <Box sx={{ 
    display: "flex",
    pointerEvents: isDisabled ? 'none' : 'auto',
    opacity: isDisabled ? 0.5 : 1,
    position: 'relative'
  }}
>
  {isDisabled && (
    <Box
      sx={{
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: 9999,
      }}
    />
  )}
      <CssBaseline />
      <AppBar position="fixed" open={open} sx={{ bgcolor: "#fff" }}>
        <Toolbar>
          <CustomTypography variant="h6" noWrap component="div">
            <Link to="/dashboard">
              <img
                src={clearbiz3}
                className="clearbiz-img"
                alt="Clearbiz Logo"
                style={{ width: "180px", height: "auto", marginBottom: "8%", marginRight: "2%" }}
              />
            </Link>
          </CustomTypography>
          {location.pathname === "/dashboard" && (
            <Search
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value.trim())}
              title="Enter search keyword"
            >
              <StyledInputBase
                placeholder="search with GSTIN or PAN"
                inputProps={{ "aria-label": "search", maxLength: 15 }}
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value.trim())}
                onInput={(e) => {
                  e.target.value = ("" + e.target.value).toUpperCase().trim();
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    e.preventDefault();
                    onSearchClick();
                  }
                }}
              />
              <SearchIconWrapper>
                <SearchIcon onClick={() => onSearchClick()} />
              </SearchIconWrapper>
            </Search>
          )}
          <Box sx={{ flexGrow: 1 }} />
          <Box sx={{ display: 'flex' }}>
            {/* Notification Icon */}
            <IconButton
              size="large"
              aria-label="show notifications"
              color="inherit"
              onClick={(event) => setNotificationAnchorEl(event.currentTarget)}
            >
              <Badge badgeContent={newNotificationsCount} color="error">
                <NotificationsIcon style={{ color: "#2b4257", fontSize: "1.8rem" }} />
              </Badge>
            </IconButton>

            {/* Menu Icon */}
            <IconButton
              size="large"
              aria-label="menu"
              color="inherit"
              onClick={(event) => setMobileMoreAnchorEl(event.currentTarget)}
            >
              <MenuIcon style={{ color: "#2b4257", fontSize: "1.8rem" }} />
            </IconButton>

            {/* Mobile Menu */}
            <Menu
              anchorEl={mobileMoreAnchorEl}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(mobileMoreAnchorEl)}
              onClose={handleMobileMenuClose}
            >
              <MenuItem onClick={() => navigate('/profile')}>
                <IconButton size="large" color="inherit">
                  <AccountCircle />
                </IconButton>
                <p style={{ marginBottom: '1px' }}>Profile</p>
              </MenuItem>
              <MenuItem onClick={handleOpenDialog}>
                <IconButton size="large" color="inherit">
                  <ExitToAppIcon />
                </IconButton>
                <p style={{ marginBottom: '1px' }}>Sign out</p>
              </MenuItem>
            </Menu>

            {/* Notification Popover */}
            <Popover
              open={openNotification}
              anchorEl={notificationAnchorEl}
              onClose={handleNotificationBadge}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              style={{ maxHeight: "60vh" }}
            >
              <div className="notification">
                {notificationsData && notificationsData.length > 0 ? (
                  notificationsData.map((notification, index) => (
                    <div
                      key={notification.id}
                      className="notification"
                      style={{ marginTop: `${index === 0 ? 15 : 5}px` }}
                    >
                      <button
                        style={{
                          ...(notification.is_read ? textStyleRead : textStyleUnread),
                          all: "unset",
                          cursor: "pointer",
                        }}
                      >
                        {notification.title}
                      </button>
                      <p style={{ marginBottom: "5px" }}>{notification.description}</p>
                      {index < notificationsData.length - 1 && (
                        <hr style={{ marginBottom: "5px" }} />
                      )}
                    </div>
                  ))
                ) : (
                  <p>No notifications to display.</p>
                )}
              </div>
              <NotificationDropdown
                notifications={dummyNotifications}
                onClose={handleNotificationClose}
              />
            </Popover>
          </Box>
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={open}>
        <Box sx={{ flexGrow: 1 }} />
        <Box sx={{ pb: 2 }}>
          <Drawer variant="permanent" open={open}>
            <DrawerHeader>
              <IconButton onClick={handleDrawerClose}>
                {theme.direction === "rtl" ? <ChevronRightIcon /> : <ChevronLeftIcon />}
              </IconButton>
            </DrawerHeader>
            {/* <Divider /> */}
            <List>
              {navList.map((item, index) => (
                <StyledTooltip key={item.title} title={item.title} placement="right">
                  <ListItem
                    key={item.title}
                    disablePadding
                    sx={{ display: "block" }}
                    onClick={() => onClickSideMenuItem(item)}
                  >
                    <ListItemButton
                      sx={{
                        minHeight: 48,
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                        paddingLeft: '16px', // Reduced left padding
                        gap: '8px', // Reduced gap between icon and text
                        "&:hover": {
                          backgroundColor: '#2b4257',
                          color: 'white',
                        },
                      }}
                    >
                      <ListItemIcon
                        sx={{
                          minWidth: '29.62px',
                          height: '29.62px',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          marginLeft: '-4px', // Negative margin to pull left
                        }}
                      >
                        <i className={`no-active-sidebar ${item.icon}`} style={{ fontSize: "18.51px" }}></i>
                      </ListItemIcon>
                      <ListItemText
                        primary={item.title}
                        sx={{
                          margin: 0,
                          '& .MuiTypography-root': {
                            fontWeight: "bold",
                          }
                        }}
                      />
                    </ListItemButton>
                  </ListItem>
                </StyledTooltip>
              ))}
            </List>
            {subscriptionType === 4 && 
              <List>
                <Typography variant="h7" sx={{ 
                  padding: '1px', 
                  color:"white",
                  fontWeight: '400',
                  backgroundColor:"#434343",
                  alignItems:"center",
                  justifyContent:"center",
                  display:"flex",
                  fontFamily:"Saira",
                  // borderRadius:"12px",
                  marginBottom:"16px"
                  }}>
                  Employee
                </Typography>
                {employeeNavList.map((item, index) => (
                  <StyledTooltip key={item.title} title={item.title} placement="right">
                    <ListItem
                      key={item.title}
                      disablePadding
                      sx={{ display: "block" }}
                      onClick={() => onClickSideMenuItem(item)}
                    >
                      <ListItemButton
                        sx={{
                          minHeight: 48,
                          justifyContent: 'flex-start',
                          alignItems: 'center',
                          paddingLeft: '16px', // Reduced left padding
                          gap: '8px', // Reduced gap between icon and text
                          "&:hover": {
                            backgroundColor: '#2b4257',
                            color: 'white',
                          },
                        }}
                      >
                        <ListItemIcon
                          sx={{
                            minWidth: '29.62px',
                            height: '29.62px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            marginLeft: '-4px', // Negative margin to pull left
                          }}
                        >
                          <i className={`no-active-sidebar ${item.icon}`} style={{ fontSize: "18.51px" }}></i>
                        </ListItemIcon>
                        <ListItemText
                          primary={item.title}
                          sx={{
                            margin: 0,
                            '& .MuiTypography-root': {
                              fontWeight: "bold",
                            },
                          }}
                        />
                      </ListItemButton>
                    </ListItem>
                  </StyledTooltip>
                ))}
              </List>}
            <Box sx={{ flexGrow: 1 }} />
          </Drawer>
          <Dialog
            open={openDialog}
            onClose={handleCloseDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            sx={{ '& .MuiDialog-paper': { width: '500px' } }}
          >
            <DialogTitle id="alert-dialog-title">{"Confirm Sign Out"}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Are you sure you want to exit?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseDialog} autoFocus color="primary">
                No
              </Button>
              <Button onClick={onClickLogout} color="primary" >
                Yes
              </Button>
            </DialogActions>
          </Dialog>
        </Box>
      </Drawer>
    </Box>
  );
}
