import React from 'react';
import { Grid, Typography, Paper, Button } from '@mui/material';
import HeroSectionImg from '../../assets/HeroSectionImg.png';
import { useNavigate } from 'react-router-dom';
import nvidia from '../../assets/nvidia.png';
import startupindia from '../../assets/startupindia.png';

const HeroSection = () => {
  const navigate = useNavigate();
  return (
    <Paper elevation={0} sx={{marginTop:'2%',marginBottom:{xs:'2%',sm:'0%'}}}>
      <Grid container spacing={2} sx={{ padding: '2rem' }}>
        <Grid item xs={12} sm={6}>
          <Grid container direction="column" spacing={2} sx={{width:"80%",marginLeft:{sm:'10%',xs:'5%'}}}>
            <Grid item>
              <Typography 
                sx={{ 
                  fontSize: { xs: '1.2rem', sm: '1.75rem' }, 
                  color: '#000',
                  marginTop:{xs:'5%',sm:'7%'},
                  fontFamily: 'Times New Roman',
                  textAlign: 'center',
                }}
              >
                Connecting Business For A Better Tomorrow
              </Typography>
            </Grid>
            <Grid item>
              <Typography 
                sx={{
                  fontFamily: 'NanumGothic',
                  textAlign: 'center',
                  fontSize:{xs:'1rem',sm:'0.9rem'},
                  display: { xs: 'none', sm: 'block' }
                }}
              >
                Unite your business ecosystem with ClearBiz, the B2B rating platform that strengthens connections between companies, suppliers, distributors, and retailers.
              </Typography>
            </Grid>
            <Grid item container justifyContent="center">
              <Button 
                onClick={() => navigate('/login')}
                sx={{
                  backgroundColor: '#2B4257',
                  color: '#fff',
                  marginTop:{xs:'0%',sm:'2%'},
                  width: {xs:'30%',sm:'25%'},
                  borderRadius: '20px',
                  marginBottom:{xs:'7%',sm:'0%'},
                  border: '1px solid #2B4257',
                  '&:hover': {
                    backgroundColor: '#2B4257',
                  }
                }}
              >
                Login
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={6}>
          <img 
            src={HeroSectionImg} 
            alt="Group53" 
            style={{ 
              width: '90%', 
              height: 'auto', 
              maxWidth: '100%' 
            }} 
          />
          <img src={nvidia} alt="nvidia" 
          style={{
            width: '25%',
            height: '20%',
            marginLeft:'20%',
            marginRight:'6%',
          }}/>
          <img src={startupindia} alt="startupindia" 
          style={{
            width: '22%',
            height: '14%',
            marginTop:'2%',
          }}/>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default HeroSection;
