import React from "react";
import { Navigate } from "react-router-dom";
import PropTypes from 'prop-types';

const AdminRoute = ({ component: Component, ...rest }) => {
  const userDetailString = localStorage.getItem("UserDetail");
  const userDetail = userDetailString ? JSON.parse(userDetailString) : {};
  const is_staff =userDetail.is_staff=== true;

  return is_staff ? <Component {...rest} /> : <Navigate to="/login" />;
};

AdminRoute.propTypes = {
  component: PropTypes.elementType.isRequired,
};

export default AdminRoute;
