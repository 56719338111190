import React, { useEffect, useState } from 'react';
import SideBar from '../../components/sidebar';
import { Box, Grid, useTheme, Typography, useMediaQuery, Button, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import { getLableAndValuesFromData } from "../../utils";
import { useDispatch, useSelector } from "react-redux";
import { globalConfigAction, bulkUploadAction, clearBulkUploadAction } from '../../store/actions';
import GetAppIcon from '@mui/icons-material/GetApp';
import ClearBizGSTBulkRating from '../../assets/ClearBizGSTBulkRating.xlsx';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import Loader from "../../components/loader";
import { toast } from 'react-toastify';
import Tooltip from '@mui/material/Tooltip';

const BulkRating = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'md'));
    const dispatch = useDispatch();
    const { successData: globalSuccessData } =
        useSelector((state) => state.globalConfigData) || {};
    const { successData: bulkUploadData, loading, error, errorInfo } = useSelector(
        (state) => state.bulkUploadReducer
    ) || {};

    const [industryTypes, setIndustryTypes] = useState([]);
    const [partnerTypes, setPartnerTypes] = useState([]);
    const [selectedIndustry, setSelectedIndustry] = useState('4');
    const [selectedPartnerType, setSelectedPartnerType] = useState('3');
    const [selectedFile, setSelectedFile] = useState(null);
    const [errorMessage, setErrorMessage] = useState(''); // State for error message
    const [isDialogOpen, setIsDialogOpen] = useState(false); // State for popup visibility
    const fileInputRef = React.useRef(null);
    const [showResultModal, setShowResultModal] = useState(false);

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            const fileType = file.type;
            const validTypes = [
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', // .xlsx
                'application/vnd.ms-excel' // .xls
            ];

            if (!validTypes.includes(fileType)) {
                setErrorMessage('Please select a valid Excel file (.xlsx or .xls)');
                setSelectedFile(null); // Clear the selected file
                event.target.value = ''; // Clear the input field
                return;
            }

            setErrorMessage(''); // Clear any previous error message
            setSelectedFile(file);
        }
    };
    const handleFileUpload = () => {
        console.log("Selected Industry:", selectedIndustry);
        console.log("Selected Partner Type:", selectedPartnerType);

        if (selectedFile) {
            const formData = new FormData();
            formData.append('file', selectedFile);
            formData.append('industry', selectedIndustry);
            formData.append('partnerType', selectedPartnerType);

            // Dispatch the bulkUploadAction with the formData
            dispatch(bulkUploadAction(formData));
            if (fileInputRef.current) {
                fileInputRef.current.value = '';
            }
        } else {
            console.log("No file selected");
        }
    };

    useEffect(() => {
        if (globalSuccessData) {
            setIndustryTypes(
                getLableAndValuesFromData(globalSuccessData.industry_type)
            );
            setPartnerTypes(
                getLableAndValuesFromData(globalSuccessData.partner_type)
            );
        }
    }, [globalSuccessData]);

    useEffect(() => {
        // Fetch profile data when component mounts
        dispatch(globalConfigAction());
    }, [dispatch]);

    //use effect to hanlde bulk uploading
    useEffect(() => {
        if (bulkUploadData) {
            setShowResultModal(true)
            setSelectedFile(null);
        }
        if (error) {
            setShowResultModal(false)
            toast.error(errorInfo)
        }
    }, [bulkUploadData]);

    useEffect(() => {
        return () => {
            dispatch(clearBulkUploadAction());
        };
    }, [dispatch]);


    return (
        <Box sx={{ display: "flex", flexDirection: isMobile ? 'column' : 'row' }}>
            <SideBar />
            <Box
                component="main"
                sx={{
                    flexGrow: 1,
                    p: { xs: 1, sm: 2, md: 3 },
                    width: isMobile ? '86%' : '100%',
                }}
                mt={isMobile ? 8 : 8}
                mb={5}
                ml={isMobile ? 7 : 0}
            >
                <div>
                    <Grid
                        container
                        spacing={2}
                        sx={{
                            boxShadow: '0px 0px 10px 0px rgba(0, 0, 0, 0.2)',
                            width: { xs: '95%', sm: '90%' },
                            minHeight: '60vh',
                            padding: { xs: 2, sm: 3 },
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                    >
                        <div style={{ display: "inline-block", marginLeft: isMobile ? '30%' : isTablet ? '55%' : '80%' }}>
                            <Button style={{ color: "red", fontSize: "18px" }} onClick={() => setIsDialogOpen(true)}>Instructions!</Button>
                        </div>
                        <h1 style={{
                            textAlign: 'center',
                            fontSize: isMobile ? '1.5rem' : '2rem',
                            marginBottom: '2%',
                            marginTop: isMobile ? '10%' : '3%',
                            background: 'linear-gradient(90deg, #65A3DD 0%, #1584D4 48.6%, #5D8FBD 100%)',
                            WebkitBackgroundClip: 'text',
                            WebkitTextFillColor: 'transparent',
                            fontWeight: 'bold',
                        }}>
                            Bulk Rating
                        </h1>
                        {loading && (
                            <Box
                                sx={{
                                    position: 'fixed',
                                    top: 0,
                                    left: 0,
                                    right: 0,
                                    bottom: 0,
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                                    zIndex: 9999
                                }}
                            >
                                <Loader />
                            </Box>
                        )}                       
                         <Grid item xs={12} md={8} sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'column', md: 'row' }, justifyContent: 'center', gap: '20px' }}>
                            <select
                                value={selectedIndustry}
                                onChange={(e) => setSelectedIndustry(e.target.value)}
                                style={{
                                    padding: '12px',
                                    border: 'double 2px transparent',
                                    backgroundImage: 'linear-gradient(white, white), linear-gradient(90deg, #0057ED 0%, #2C5172 100%)',
                                    backgroundOrigin: 'border-box',
                                    backgroundClip: 'padding-box, border-box',
                                    borderRadius: '10px',
                                    width: isMobile ? '250px' : isTablet ? '250px' : '400px',
                                }}
                            >
                                <option value="">Select Industry</option>
                                {industryTypes.map((type, index) => (
                                    <option key={index} value={type.value}>
                                        {type.label}
                                    </option>
                                ))}
                            </select>

                            <select
                                value={selectedPartnerType}
                                onChange={(e) => setSelectedPartnerType(e.target.value)}
                                style={{
                                    padding: '12px',
                                    border: 'double 2px transparent',
                                    backgroundImage: 'linear-gradient(white, white), linear-gradient(90deg, #0057ED 0%, #2C5172 100%)',
                                    backgroundOrigin: 'border-box',
                                    backgroundClip: 'padding-box, border-box',
                                    borderRadius: '10px',
                                    width: isMobile ? '250px' : isTablet ? '250px' : '400px',
                                }}
                            >
                                <option value="">Select Business Type</option>
                                {partnerTypes.map((type, index) => (
                                    <option key={index} value={type.value}>
                                        {type.label}
                                    </option>
                                ))}
                            </select>
                        </Grid>

                        <Grid item xs={10}>
                            <Box sx={{
                                width: { xs: '100%', sm: '80%', md: '80%' },
                                margin: '20px auto',
                                padding: '20px',
                                background: 'rgba(248, 252, 255, 1)',
                                border: '1px dashed #553EBD',
                                borderRadius: '4px'
                            }}>
                                <input
                                    ref={fileInputRef}
                                    type="file"
                                    accept=".xlsx, .xls"
                                    onChange={handleFileChange}
                                    style={{
                                        width: isMobile ? '100%' : 'auto',
                                        display: 'inline-block',
                                        cursor: 'pointer',
                                        fontSize: isMobile ? '12px' : '16px',
                                    }}
                                />
                            </Box>
                            <div style={{ textAlign: 'center' }}>
                                {errorMessage && (
                                    <p style={{ color: 'red', marginTop: "0px" }}>{errorMessage}</p>
                                )}
                            </div>
                        </Grid>

                        <Grid item xs={12} style={{ textAlign: 'center' }}>
                              <Tooltip title={!selectedFile ? "Please select the file" : ""}>
                                <span>
                                  <button
                                    onClick={handleFileUpload}
                                    disabled={!selectedFile}
                                    style={{
                                      margin: '20px auto',
                                      padding: isMobile ? '12px 24px' : '8px 40px',
                                      background: !selectedFile 
                                        ? '#cccccc'
                                        : 'linear-gradient(90deg, #47627B 0%, #4A87BE 100%)',
                                      borderRadius: '15px',
                                      border: 'none',
                                      color: !selectedFile ? '#000' : '#ffffff',
                                      cursor: !selectedFile ? 'not-allowed' : 'pointer',
                                      opacity: !selectedFile ? 0.5 : 1,
                                      transition: 'all 0.3s ease'
                                    }}
                                  >
                                    Submit
                                  </button>
                                </span>
                              </Tooltip>
                          </Grid>
                      </Grid>
                    <Dialog open={isDialogOpen} onClose={() => setIsDialogOpen(false)} fullWidth maxWidth="sm">
                        <DialogContent>
                            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '16px' }}>
                                <a href={ClearBizGSTBulkRating} download="ClearBizGSTBulkRating.xlsx" style={{ fontSize: isMobile ? '15px' : '18px', textDecoration: 'none', display: 'flex', color: '#fff', background: 'linear-gradient(270deg, #22D1EE 0%, #3D5AF1 100%)', padding: '2% 8%', borderRadius: '15px', marginLeft: isMobile ? '10%' : '22%' }}>
                                    Download Template
                                    <GetAppIcon style={{ marginRight: '8px' }} />
                                </a>
                            </div>
                            <DialogTitle fontWeight='bold'>Bulk Rating Upload Instructions</DialogTitle>
                            <Typography variant="body1" gutterBottom marginLeft={"5%"} color='#000'>
                                <ul>
                                    <li>Use Sample Excel Format: Only upload files in the provided template format.</li>
                                    <li>Max 50 Records: Each upload can include up to 50 user details.</li>
                                    <li>Select Industry & Business Type: Choose these before submitting.</li>
                                    <li>Mandatory Details: Fill in GST Number, Mobile Number, Rating, Outstanding, and Comments.</li>
                                    <li>Fill Carefully: Ensure all data is accurate to avoid issues.</li>
                                    <li>User Responsibility: You are responsible for incorrect inputs.</li>
                                    <li>Support: Contact support for any problems.</li>
                                    <li>Review Errors: Check for failed updates after submission and correct as needed.</li>
                                </ul>
                            </Typography>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => setIsDialogOpen(false)} color="primary">
                                Close
                            </Button>
                        </DialogActions>
                    </Dialog>
                    <Dialog
                        open={showResultModal}
                        onClose={() => setShowResultModal(false)

                        }
                        maxWidth="sm"
                        fullWidth
                    >
                        <DialogTitle sx={{
                            textAlign: 'center',
                            background: 'linear-gradient(90deg, #65A3DD 0%, #1584D4 48.6%, #5D8FBD 100%)',
                            color: 'white'
                        }}>
                            Bulk Upload Results
                        </DialogTitle>
                        <DialogContent sx={{ mt: 2 }}>
                            <Box sx={{ textAlign: 'center', py: 2 }}>
                                {bulkUploadData?.success_count > 0 ? (
                                    <CheckCircleIcon sx={{ fontSize: 60, color: 'green' }} />
                                ) : (
                                    <ErrorIcon sx={{ fontSize: 60, color: 'red' }} />
                                )}

                                <Typography variant="h6" sx={{ mt: 2 }}>
                                    Successfully Uploaded: {bulkUploadData?.success_count || 0} records
                                </Typography>

                                {bulkUploadData?.errors?.length > 0 && (
                                    <Box sx={{ mt: 2, maxHeight: 200, overflowY: 'auto' }}>
                                        <Typography variant="subtitle1" color="error" sx={{ fontWeight: 'bold', mb: 1 }}>
                                            Errors Found:
                                        </Typography>
                                        {bulkUploadData.errors.map((error, index) => (
                                            <Typography
                                                key={index}
                                                sx={{
                                                    p: 1,
                                                    mb: 1,
                                                    backgroundColor: '#fff3f3',
                                                    borderRadius: 1,
                                                    border: '1px solid #ffcdd2'
                                                }}
                                            >
                                                {error}
                                            </Typography>
                                        ))}
                                    </Box>
                                )}
                            </Box>
                        </DialogContent>
                        <DialogActions>
                            <Button
                                onClick={() => setShowResultModal(false)}
                                sx={{
                                    background: 'linear-gradient(90deg, #47627B 0%, #4A87BE 100%)',
                                    color: 'white',
                                    '&:hover': {
                                        background: 'linear-gradient(90deg, #4A87BE 0%, #47627B 100%)',
                                    }
                                }}
                            >
                                Close
                            </Button>
                        </DialogActions>
                    </Dialog>
                </div>
            </Box>
        </Box>
    );
};

export default BulkRating;
