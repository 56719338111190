import React,{useEffect,useState}from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getadminInctiveUsersAction } from "../../../store/actions";
import Table from '../../../components/table';
import TableData from '../../../utils/tableutils';
import { Box, Typography,TextField,Button } from '@mui/material';
import Loader from '../../../components/loader';
import SearchIcon from "@mui/icons-material/Search";
import {postAdminInActiveUserEmailNotification} from "../../../store/actions";
import { toast } from 'react-toastify';
import { Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';



function InactiveUserList() {
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const { successData, loading } = useSelector(state => state.getadminInctiveUsersAction);
  const { successData: successDataEmail } = useSelector(state => state.postAdminInactiveUsersNotificationReducer);
  const [searchValue, setSearchValue] = useState('');
  
  const [openDialog, setOpenDialog] = useState(false);

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

    const onClickEmailHandler = (user) => {
      const Payload = {
        "user_id": user.id,
      }
      dispatch(postAdminInActiveUserEmailNotification(Payload));
    }

    const handleBulkEmailNotification = () => {
      const userIds = data.map(user => user.id);
      const payload = {
        user_ids: userIds
      };
      dispatch(postAdminInActiveUserEmailNotification(payload));
      handleCloseDialog();
    };
    

    const columns = React.useMemo(() => TableData.inActiveUsers, []);
  
  let content 

  if (loading) {
    content = <Loader/>
  }
  else if (data.length > 0) {
    content = <Table columns={columns} data={data} onClickEmailReminder={onClickEmailHandler} />;
  }
  else {
    content = <p>No Inavative Users</p>
  }

  const onSearchChange = (e) => {
    const value = e.target.value.toLowerCase();
    setSearchValue(value);

    if (value !== "") {
      const filteredData = successData.filter((item) => {
        return item?.business?.name
          .toLowerCase()
          .includes(value);
      });
      setData(filteredData);
    } else {
      setData(successData);
    }
  };

  useEffect(() => {
    if (successDataEmail) {
      toast.success("Email Notification Sent Successfully");
    }
  },[successDataEmail])

  useEffect(() => {
        dispatch(getadminInctiveUsersAction());
    }, [dispatch]);
  
    useEffect(() => {
        if (successData) {
            setData(successData);
        }
    }, [successData,dispatch]);



    return (
       <Box height="82vh" boxShadow={4} m={2} p={2} sx={{overflow:"auto",'& .MuiBox-root': {overflow: 'auto'}}} >
      <Box>
        <Typography variant="h4" mb={2}>Inactive Users</Typography> 
      </Box>
        <Box mb={2} display="flex" justifyContent="space-between" alignItems="center">
          <Box>
            <TextField
              id="search"
              onChange={onSearchChange}
              label="Search"
              variant="standard"
              value={searchValue}
            />
            <SearchIcon sx={{ color: "#0e3c58", mr: 1, my: 2.2 }} />
          </Box>
          <Button
            variant="contained"
            onClick={handleOpenDialog}
            sx={{
              backgroundColor: '#0e3c58',
              color: 'white',
              '&:hover': {
                backgroundColor: '#0a2e44',
              },
              marginLeft: 'auto',
              padding: '8px 16px',
              borderRadius: '4px',
              textTransform: 'none',
              fontWeight: 500
            }}
          >
            Send Reminder to All Users
          </Button>
        </Box>
        <Dialog open={openDialog} onClose={handleCloseDialog}>
          <DialogTitle>Confirm Email Reminder</DialogTitle>
          <DialogContent>
            Do you want to send email reminders to all inactive users?
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDialog} sx={{ color: '#0e3c58' }}>
              Cancel
            </Button>
            <Button
              onClick={handleBulkEmailNotification}
              variant="contained"
              sx={{
                backgroundColor: '#0e3c58',
                '&:hover': {
                  backgroundColor: '#0a2e44',
                }
              }}
            >
              Send
            </Button>
          </DialogActions>
        </Dialog>
          {content} 
      </Box>
    );
}

export default InactiveUserList;

        