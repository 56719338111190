import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Sidebar from "../../components/sidebar";
import { clearSearchPartnerActionData} from "../../store/actions";
import { dispatchAll } from "../../utils";
import "./noresultssearch.scss";
import notfound1 from "../../assets/notfound1.png";

const Noresultssearch = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    successData: searchData,} = useSelector((state) => state.searchPartnersData) || {};

  React.useEffect(() => {
    if (searchData && searchData.length > 0) {
      navigate("/searchprofile", {
        state: { ...searchData[0] },
        replace: false,
      });
    } else if (searchData && searchData.length === 0) {
      toast.success("no results found");
    }
  }, [searchData,navigate]);

  React.useEffect(() => {
    return () => dispatchAll([clearSearchPartnerActionData], dispatch);
  }, [dispatch]);

  return (
    <>
      <Sidebar />

      <div
          style={{
            marginLeft: '10%',
            justifyContent: 'center',
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
            minHeight: '100vh',
          }}
        >
          <img src={notfound1} alt="No Employee Found" style={{ width: '100%', maxWidth: '300px' }} />
          <h3
            style={{
              background: 'linear-gradient(90deg, #2B4257 0%, #625DBD 100%)',
              WebkitBackgroundClip: 'text',
              WebkitTextFillColor: 'transparent',
            }}
          >
            No Results Found
          </h3>
          <h6 style={{ marginLeft: {sm:"0%",md:'0%',xs:'10%'} }}>
            We couldn’t find your partner.You can add a new one
          </h6>
          <button
            onClick={() => {
              navigate('/newcustomer');
            }}
            style={{
              background: 'linear-gradient(90deg, #553EBD 0%, #7A538E 100%)',
              color: '#fff',
              borderRadius: '15px',
              margin: '1% 0% 0% 0%',
              padding: '0.5% 5%',
            }}
          >
            Add Partner
          </button>
      </div>
    </>
  );
};

export default Noresultssearch;
