import React, { useState } from 'react';
import SideBar from "../../../components/sidebar";
import { Box, Paper, Typography, Grid, TextField, InputAdornment, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

function EmployeeEdit() {
    const navigate = useNavigate();
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [designation, setDesignation] = useState('');
    const [remarks, setRemarks] = useState('');

    const designationOptions = [
        'designation1',
        'designation2',
        'designation3',
    ];

    return (
        <Box sx={{ display: 'flex', minHeight: '100vh' }}>
            <SideBar />
            <Box sx={{ flexGrow: 1, p: { xs: 2, sm: 3 } }}>
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mt: { xs: '15%', sm: '10%', md: '5%' } }}>
                    <Paper sx={{ width: { xs: '95%', sm: '80%', md: '60%' }, p: { xs: '20px', sm: '30px', md: '40px' }, boxShadow: '0px 0px 10.1px 0px rgba(0, 0, 0, 0.25)' }}>
                        <Typography variant='h4' sx={{ fontSize: { xs: '1.5rem', sm: '2rem', md: '2.5rem' }, fontWeight: 'bold', textAlign: 'center', fontFamily: 'Inter', background: 'linear-gradient(90deg, #65A3DD 0%, #1584D4 48.6%, #5D8FBD 100%)', WebkitBackgroundClip: 'text', WebkitTextFillColor: 'transparent', mb: 4 }}>
                            Rate Employee
                        </Typography>

                        <Grid container spacing={3} mt={2}>
                            <Grid item xs={12} md={6}>
                                <Typography sx={{ mb: 2, background: 'linear-gradient(269.25deg, #2B4257 20.4%, #5D8FBD 99.43%)', WebkitBackgroundClip: 'text', WebkitTextFillColor: 'transparent', fontSize: '1rem', fontWeight: 'bold' }}>
                                    Working Period
                                </Typography>
                                <Box display='flex' gap={2} flexDirection={{ xs: 'column', sm: 'row' }}>
                                    <TextField
                                        type="date"
                                        value={startDate}
                                        onChange={(e) => setStartDate(e.target.value)}
                                        InputProps={{
                                            sx: {
                                                fontSize: '16px',
                                                border: '1px solid',
                                                borderRadius: '5px',
                                                borderImage: 'linear-gradient(90deg, #0057ED 0%, #2C5172 100%) 1',
                                                '& .MuiOutlinedInput-notchedOutline': { border: 'none' },
                                            },
                                        }}
                                        sx={{
                                            width: '100%',
                                            borderRadius: '5px',
                                            '& .MuiInputBase-root': { paddingRight: '8px' },
                                            '& .MuiInputBase-input': { padding: '4px 6px' },
                                        }}
                                    />
                                    <TextField
                                        type="date"
                                        value={endDate}
                                        onChange={(e) => setEndDate(e.target.value)}
                                        InputProps={{
                                            sx: {
                                                fontSize: '16px',
                                                border: '1px solid',
                                                borderRadius: '5px',
                                                borderImage: 'linear-gradient(90deg, #0057ED 0%, #2C5172 100%) 1',
                                                '& .MuiOutlinedInput-notchedOutline': { border: 'none' },
                                            },
                                        }}
                                        sx={{
                                            width: '100%',
                                            borderRadius: '5px',
                                            '& .MuiInputBase-root': { paddingRight: '8px' },
                                            '& .MuiInputBase-input': { padding: '4px 6px' },
                                        }}
                                    />
                                </Box>
                            </Grid>

                            <Grid item xs={12} md={6}>
                                <Typography sx={{ mb: 2, background: 'linear-gradient(269.25deg, #2B4257 20.4%, #5D8FBD 99.43%)', WebkitBackgroundClip: 'text', WebkitTextFillColor: 'transparent', fontSize: '1rem', fontWeight: 'bold' }}>
                                    Designation
                                </Typography>
                                <TextField
                                    select
                                    SelectProps={{ native: true }}
                                    value={designation}
                                    onChange={(e) => setDesignation(e.target.value)}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <Box sx={{ width: '1px', height: '100%', backgroundColor: '#9CA3AF' }} />
                                            </InputAdornment>
                                        ),
                                        sx: {
                                            fontSize: '16px',
                                            border: '1px solid',
                                            borderRadius: '5px',
                                            borderImage: 'linear-gradient(90deg, #0057ED 0%, #2C5172 100%) 1',
                                            '& .MuiOutlinedInput-notchedOutline': { border: 'none' },
                                        },
                                    }}
                                    sx={{
                                        width: '100%',
                                        borderRadius: '5px',
                                        '& .MuiInputBase-root': { paddingRight: '8px' },
                                        '& .MuiInputBase-input': { padding: '4px 6px' },
                                    }}
                                >
                                    <option value="" disabled>Select Designation</option>
                                    {designationOptions.map((option, index) => (
                                        <option key={index} value={option}>{option}</option>
                                    ))}
                                </TextField>
                            </Grid>

                            <Grid item xs={12} ml={{xs:0,sm:18}} mt={3}>
                                <Typography sx={{ mb: 2, background: 'linear-gradient(269.25deg, #2B4257 20.4%, #5D8FBD 99.43%)', WebkitBackgroundClip: 'text', WebkitTextFillColor: 'transparent', fontSize: '1rem', fontWeight: 'bold' }}>
                                    Remarks
                                </Typography>
                                <TextField
                                    value={remarks}
                                    onChange={(e) => setRemarks(e.target.value)}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <Box sx={{ width: '1px', height: '100%', backgroundColor: '#9CA3AF' }} />
                                            </InputAdornment>
                                        ),
                                        sx: {
                                            width:{xs:'100%',sm:'70%'},
                                            fontSize: '16px',
                                            border: '1px solid',
                                            borderRadius: '5px',
                                            borderImage: 'linear-gradient(90deg, #0057ED 0%, #2C5172 100%) 1',
                                            '& .MuiOutlinedInput-notchedOutline': { border: 'none' },
                                        },
                                    }}
                                    sx={{
                                        width: '100%',
                                        borderRadius: '5px',
                                        '& .MuiInputBase-root': { paddingRight: '8px' },
                                        '& .MuiInputBase-input': { padding: '4px 6px' },
                                    }}
                                />
                            </Grid>
                        </Grid>

                        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 5 }}>
                            <Button
                                onClick={() => navigate('/EmployeeDashboard')}
                                sx={{
                                    background: 'linear-gradient(90deg, #47627B 0%, #4A87BE 100%)',
                                    color: '#fff',
                                    px: 4,
                                    py: 1,
                                    '&:hover': {
                                        background: 'linear-gradient(90deg, #4A87BE 0%, #47627B 100%)',
                                    }
                                }}
                            >
                                Submit
                            </Button>
                        </Box>
                    </Paper>
                </Box>
            </Box>
        </Box>
    );
}

export default EmployeeEdit;
