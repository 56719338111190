// create functional component RateMyCustomer
import React from "react";
import PropTypes from 'prop-types';
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { toast } from "react-toastify";
import {
  clearMyPartnerActionData,
  myPartnerAction
} from "../../store/actions";
import { dispatchAll } from "../../utils";
import DropdownItems from "../../utils/dropdownutils";
import { moredetails } from "../moredetails/schemaformoredetails";
import "./moredetails.scss";
const RateMyCustomer = ({ isEdit, partnerData, onUpdateRatingSuccess }) => {
  const dispatch = useDispatch();

  const {
    successData: ratingData,
    error: ratingError,
    errorInfo: ratingErrorInfo,
  } = useSelector((state) => state.myPartnerData) || {};

  React.useEffect(() => {
    if (ratingData) {
      onUpdateRatingSuccess();
      toast.success("Rating updated successfully");
    }
  }, [ratingData, onUpdateRatingSuccess]);

  React.useEffect(() => {
    if (ratingError) {
      toast.error(ratingErrorInfo);
    }
  }, [ratingError,ratingErrorInfo]);
  React.useEffect(() => {
    return () => dispatchAll([clearMyPartnerActionData], dispatch);
  }, [dispatch]);
  const initialValues = {
    score: isEdit
      ? DropdownItems.score.filter((v) => v.value === partnerData.score)[0]
      : {},
    totaloutstanding:
      isEdit && partnerData.total_outstanding !== ""
        ? DropdownItems.totaloutstanding.filter(
            (v) => v.value === partnerData.total_outstanding
          )[0]
        : {},
    ontimepayment:
      isEdit && partnerData.on_time_Payment
        ? DropdownItems.percentages.filter(
            (v) => v.value === partnerData.on_time_Payment
          )[0]
        : {},
    reachability:
      isEdit && partnerData.reachability
        ? DropdownItems.reachability.filter(
            (v) => v.value === partnerData.reachability
          )[0]
        : {},
    recoveryprobability:
      isEdit && partnerData.recovery_probability
        ? DropdownItems.reachability.filter(
            (v) => v.value === partnerData.recovery_probability
          )[0]
        : {},
    since: isEdit ? partnerData.known_since : "",
    comments: isEdit ? partnerData.comments : "",
    dso:
      isEdit && partnerData.dso !== ""
        ? DropdownItems.dso.filter((v) => v.value === partnerData.dso)[0]
        : {},
    gst:
      isEdit && partnerData.is_gst_paid !== ""
        ? DropdownItems.GST.filter(
            (v) => v.value === Number(partnerData.is_gst_paid)
          )[0]
        : {},
    pendingrecievable: isEdit ? partnerData.pending_receivables : 0,
  };

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
  } = useFormik({
    initialValues,
    validationSchema: moredetails,
    onSubmit: (values) => {
      const payload = {
        score: values.score.value,
        total_outstanding: values.totaloutstanding.value,
        on_time_Payment: values.ontimepayment.value,
        reachability: values.reachability.value,
        recovery_probability: values.recoveryprobability.value,
        comments: values.comments,
        dso: values.dso.value,
        is_gst_paid: values.gst.value,
        known_since: values.since,
        pending_receivables: values.pendingrecievable,
        business_partner_assoc: partnerData.business_partner_assoc.id,

      };
      dispatch(myPartnerAction(payload));
    },
  });

  return (
  
      <div className="ratemycustomer ">
        <h5 className="card-title text-center ">
          <strong>Rate My Partner</strong>
        </h5>

        <form onSubmit={handleSubmit}>
          <div className="row g-3  m-5">
            <div className="col-md-12 text-center">
              <div
                className="row align-items-center"
                style={{ marginTop: "-0px", marginBottom: "40px" }}
              >
                <div className="col-md-5 text-md-right">
                  <label htmlFor="score" className="form-label scorestyle">
                    Score
                  </label>
                </div>
                <div className="col-md-6">
                  <Select
                    defaultValue={values.score}
                    value={values.score}
                    name="score"
                    id="score"
                    onChange={(selectedScore) =>
                      setFieldValue("score", selectedScore)
                    }
                    options={DropdownItems.score}
                  />
                </div>
              </div>

              {errors.score && touched.score ? (
                <p className="form-error" style={{ color: "red" }}>
                  {errors.score.value}
                </p>
              ) : null}
            </div>

            <div className="col-md-3">
              <label htmlFor="totaloutstanding" className="form-label">
                Current Outstanding
              </label>
              <Select
                defaultValue={values.totaloutstanding}
                value={values.totaloutstanding}
                name="totaloutstanding"
                id="totaloutstanding"
                onChange={(selectedtotaloutstanding) =>
                  setFieldValue("totaloutstanding", selectedtotaloutstanding)
                }
                options={DropdownItems.totaloutstanding}
              />
            </div>

            <div className="col-md-3 top-height">
              <label htmlFor="dso" className="form-label">
                Days Since Outstanding
              </label>
              <Select
                defaultValue={values.dso}
                value={values.dso}
                name="dso"
                id="dso"
                onChange={(selecteddso) => setFieldValue("dso", selecteddso)}
                options={DropdownItems.dso}
              />
            </div>
            <div className="col-md-3 top-height">
              <label htmlFor="pendingrecievable" className="form-label">
                Pending Receivable
              </label>
              <Select
                defaultValue={values.pendingrecievable}
                value={values.pendingrecievable}
                name="pendingrecievable"
                id="pendingrecievable"
                onChange={(selectedpendingrecievable) =>
                  setFieldValue("pendingrecievable", selectedpendingrecievable)
                }
                options={DropdownItems.pendingrecievable}
              />
            </div>
            <div className="col-md-3">
              <label htmlFor="since" className="form-label">
                Since
              </label>
              <input
                type="date"
                max={new Date().toISOString().split("T")[0]}
                name="since"
                value={values.since}
                onChange={handleChange}
                onBlur={handleBlur}
                className="form-control"
                id="since"
                placeholder="since"
              />
            </div>

            <div className="col-md-3 top-height">
              <label htmlFor="reachability" className="form-label">
                Reachability{" "}
              </label>
              <Select
                defaultValue={values.reachability}
                value={values.reachability}
                name="reachability"
                id="reachability "
                onChange={(selectedreachability) =>
                  setFieldValue("reachability", selectedreachability)
                }
                options={DropdownItems.reachability}
              />
            </div>

            <div className="col-md-3 top-height">
              <label htmlFor="recoveryprobability" className="form-label">
                Recovery probability
              </label>
              <Select
                defaultValue={values.recoveryprobability}
                value={values.recoveryprobability}
                name="recoveryprobability"
                id="recoveryprobability "
                onChange={(selectedrecoveryprobability) =>
                  setFieldValue(
                    "recoveryprobability",
                    selectedrecoveryprobability
                  )
                }
                options={DropdownItems.recoveryprobability}
              />
            </div>

            <div className="col-md-3 top-height">
              <label htmlFor="ontimepayment" className="form-label">
                On-time Payment
              </label>
              <Select
                defaultValue={values.ontimepayment}
                value={values.ontimepayment}
                name="ontimepayment"
                id="ontimepayment"
                onChange={(selectedontimepayment) =>
                  setFieldValue("ontimepayment", selectedontimepayment)
                }
                options={DropdownItems.percentages}
              />
            </div>

            <div className="col-md-3 top-height">
              <label htmlFor=" transactionpaidontime" className="form-label">
                Transaction paid on time
              </label>
              <Select
                defaultValue={values.transactionpaidontime}
                value={values.transactionpaidontime}
                name=" transactionpaidontime"
                id=" transactionpaidontime"
                onChange={(selectedontimepayment) =>
                  setFieldValue(" transactionpaidontime", selectedontimepayment)
                }
                options={DropdownItems.transactionpaidontime}
              />
            </div>

            <div className="col-md-3 top-height">
              <label htmlFor="GST" className="form-label">
                GST Paid on Time
              </label>
              <Select
                defaultValue={values.gst}
                value={values.gst}
                name="gst"
                id="gst"
                onChange={(selectedgst) => setFieldValue("gst", selectedgst)}
                options={DropdownItems.GST}
              />
            </div>

            <div className="col-md-3 top-height">
              <label htmlFor="Capital / Assest worth ?" className="form-label">
                Capital worth{" "}
              </label>
              <Select
                defaultValue={values.capital}
                value={values.capital}
                name="capital"
                id="capital"
                onChange={(selectedcapital) =>
                  setFieldValue("capital", selectedcapital)
                }
                options={DropdownItems.capital}
              />
            </div>

            <div className="col-md-3 top-height">
              <label htmlFor="Capital / Assest worth ?" className="form-label">
                Assest worth{" "}
              </label>
              <Select
                defaultValue={values.capital}
                value={values.capital}
                name="capital"
                id="capital"
                onChange={(selectedcapital) =>
                  setFieldValue("capital", selectedcapital)
                }
                options={DropdownItems.capital}
              />
            </div>
            <div className="col-md-3 top-height">
              <label htmlFor="Capital / Assest worth ?" className="form-label">
                Legal Proceedings
              </label>
              <Select
                defaultValue={values.legal}
                value={values.legal}
                name="legal"
                id="legal"
                onChange={(selectedlegal) =>
                  setFieldValue("legal", selectedlegal)
                }
                options={DropdownItems.legal}
              />
            </div>
            <div className="col-md-6 top-height">
              <label htmlFor="comments" className="form-label">
                Comments
              </label>
              <textarea
                type="text"
                name="comments"
                value={values.comments}
                onChange={handleChange}
                onBlur={handleBlur}
                className="form-control"
                id="comments"
                placeholder="Comments"
              />
            </div>

            <div className="text-center col-md-12 mt-3 ">
              <button
                type="submit"
                className="btn btn-primary next-button-ratemycustomer"
              >
                Next
              </button>
            </div>
          </div>
        </form>
      </div>
   
  );
};
export default RateMyCustomer;


RateMyCustomer.propTypes = {
  isEdit: PropTypes.string.isRequired,
  onUpdateRatingSuccess: PropTypes.func.isRequired,
   partnerData:PropTypes.object.isRequired
};