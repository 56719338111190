import React from 'react';
import { Box, Typography, Paper, useTheme, useMediaQuery } from '@mui/material';
import { useState } from 'react';
import QueImage from '../../assets/que.png';
import solution1 from '../../assets/solution1.png';
import problem2 from '../../assets/problem2.png';
import solution from '../../assets/solution.png';

const TheProblemandSolution = () => {
  const theme = useTheme();
  const [showSecondBox, setShowSecondBox] = useState(false);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box>
      {isMobile ? (
        // Mobile view
        <Box sx={{ px: 2 }}>
          <Box sx={{ display: 'flex', gap: 2, flexDirection: 'column', alignItems: 'center' }}>
            <Paper
              sx={{
                backgroundColor: 'white',
                width: '100%',
                height: '350px',
                borderRadius: '10px',
                position: 'relative',
                overflow: 'hidden',
              }}
            >
              {/* Problem Section */}
              <Box
                sx={{
                  position: 'absolute',
                  width: '100%',
                  height: '100%',
                  padding: '16px',
                  opacity: showSecondBox ? 0 : 1, // Fade out effect
                  transform: `translateX(${showSecondBox ? '-100%' : '0'})`,
                  transition: 'opacity 2s ease, transform 2.5s ease-in-out',
                }}
              >
                <Typography variant="h4" sx={{ color: '#2b4257', fontWeight: 'bold' }}>
                  The Problem
                </Typography>
                <Typography variant="body1" sx={{ mt: 2, color: '#2b4257' }}>
                  Finding reliable business partners is challenging. Traditional methods are often inadequate, and there's no centralized platform to assess a partner's payment history or trustworthiness.
                </Typography>
                <img
                  src={problem2}
                  alt="Problem"
                  style={{
                    width: '180px',
                    height: '180px',
                    position: 'absolute',
                    bottom: '20px',
                    right: '20px',
                    objectFit: 'contain',
                  }}
                />
              </Box>

              {/* Solution Section */}
              <Box
                sx={{
                  position: 'absolute',
                  width: '100%',
                  height: '100%',
                  padding: '16px',
                  opacity: showSecondBox ? 1 : 0, // Fade in effect
                  transform: `translateX(${showSecondBox ? '0' : '100%'})`,
                  transition: 'opacity 2s ease, transform 2.5s ease-in-out',
                }}
              >
                <Typography variant="h4" sx={{ color: '#2b4257', fontWeight: 'bold' }}>
                  The Solution
                </Typography>
                <Typography variant="body1" sx={{ mt: 2, color: '#2b4257' }}>
                  ClearBiz simplifies partner evaluation. Our platform lets businesses rate and review partners on payment history and reliability, offering real-time insights that foster transparency and build trust.
                </Typography>
                <img
                  src={solution1}
                  alt="Solution"
                  style={{
                    width: '180px',
                    height: '180px',
                    position: 'absolute',
                    bottom: '20px',
                    right: '20px',
                    objectFit: 'contain',
                  }}
                />
              </Box>
            </Paper>

            {/* Toggle Button */}
            <Paper
              onClick={() => setShowSecondBox(!showSecondBox)}
              sx={{
                backgroundColor: '#2b4257',
                width: '100%',
                height: '80px',
                borderRadius: '10px',
                display: 'flex',
                alignItems: 'center',
                cursor: 'pointer',
              }}
            >
              <Typography variant="h4" sx={{ color: '#fff', fontWeight: 'bold', ml: '7%' }}>
                {showSecondBox ? 'The Problem' : 'The Solution'}
              </Typography>
              <img
                src={showSecondBox ? QueImage : solution}
                alt="icon"
                style={{
                  width: '60px',
                  height: '60px',
                  borderRadius: '50%',
                  marginLeft: 'auto',
                  marginRight: '20px',
                  objectFit: 'contain',
                }}
              />
            </Paper>
          </Box>
        </Box>
      ) : (
        // Desktop view
        <Box
          className="content-box"
          sx={{
            display: 'flex',
            gap: 2,
            flexDirection: 'row',
            alignItems: 'center',
            px: 0,
          }}
        >
          {!showSecondBox ? (
            <Box className="firstbox" sx={{ display: 'flex', gap: 2, flexDirection: 'row', alignItems: 'center' }}>
              {/* Solution Card on Hover */}
              <Paper
                sx={{
                  backgroundColor: '#2b4257',
                  width: { xs: '30%', sm: '40%', md: '20%' },
                  height: '350px',
                  marginLeft: '10%',
                  marginTop: '1%',
                  borderRadius: '10px',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  cursor: 'pointer',
                  transition: 'background-color 2s ease',
                }}
                onMouseEnter={() => setShowSecondBox(true)}
              >
                <img
                  src={solution}
                  alt="solution"
                  style={{
                    width: '80%',
                    height: '50%',
                    borderRadius: '50%',
                    marginTop: '10%',
                  }}
                />
                <Typography sx={{ color: 'white', marginTop: '20px', fontWeight: 'bold', fontSize: { xs: '1.5rem', sm: '1.8rem', md: '2rem' } }}>
                  The Solution
                </Typography>
              </Paper>

              {/* Problem Description */}
              <Paper
                sx={{
                  backgroundColor: 'white',
                  width: '60%',
                  height: '350px',
                  marginTop: '1%',
                  marginRight: '10%',
                  borderRadius: '10px',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'left',
                  paddingLeft: '2%',
                  justifyContent: 'space-between',
                  transition: 'background-color 2s ease',
                }}
              >
                <Typography variant="h4" sx={{ marginTop: '20px', color: '#2b4257', fontWeight: 'bold' }}>
                  The Problem
                </Typography>
                <Typography variant="body1" sx={{ fontSize: '1.2rem', lineHeight: 1.8, color: '#2b4257' }}>
                  Finding reliable business partners is challenging. Traditional methods are often inadequate, and there's no centralized platform to assess a partner's payment history or trustworthiness.
                </Typography>
                <img
                  src={problem2}
                  alt="Problem"
                  style={{
                    width: '180px',
                    height: '180px',
                    alignSelf: 'flex-end',
                    marginRight: '20px',
                    marginBottom: '10px',
                    objectFit: 'contain',
                  }}
                />
              </Paper>
            </Box>
          ) : (
            <Box sx={{ display: 'flex', gap: 2, flexDirection: 'row', alignItems: 'center' }}>
              {/* Solution Description */}
              <Paper
                sx={{
                  backgroundColor: 'white',
                  width: '60%',
                  height: '350px',
                  marginTop: '1%',
                  marginLeft: '10%',
                  borderRadius: '10px',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'left',
                  paddingLeft: '2%',
                  justifyContent: 'space-between',
                  transition: 'background-color 2s ease',
                }}
                onMouseLeave={() => setShowSecondBox(false)}
              >
                <Typography variant="h4" sx={{ marginTop: '20px', color: '#2b4257', fontWeight: 'bold' }}>
                  The Solution
                </Typography>
                <Typography variant="body1" sx={{ fontSize: '1.2rem', lineHeight: 1.8, color: '#2b4257' }}>
                  ClearBiz simplifies partner evaluation. Our platform lets businesses rate and review partners on payment history and reliability, offering real-time insights that foster transparency and build trust.
                </Typography>
                <img
                  src={solution1}
                  alt="Solution"
                  style={{
                    width: '180px',
                    height: '180px',
                    alignSelf: 'flex-end',
                    marginRight: '20px',
                    marginBottom: '10px',
                    objectFit: 'contain',
                  }}
                />
              </Paper>

              {/* Problem Card on Hover */}
              <Paper
                sx={{
                  backgroundColor: '#2b4257',
                  width: { xs: '30%', sm: '40%', md: '20%' },
                  height: '350px',
                  marginRight: '10%',
                  marginTop: '1%',
                  borderRadius: '10px',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  cursor: 'pointer',
                  transition: 'background-color 2s ease',
                }}
                onMouseLeave={() => setShowSecondBox(false)}
              >
                <img
                  src={QueImage}
                  alt="Problem"
                  style={{
                    width: '80%',
                    height: '50%',
                    borderRadius: '50%',
                    marginTop: '10%',
                  }}
                />
                <Typography sx={{ color: 'white', marginTop: '20px', fontWeight: 'bold', fontSize: { xs: '1.5rem', sm: '1.8rem', md: '2rem' } }}>
                  The Problem
                </Typography>
              </Paper>
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
};

export default TheProblemandSolution;
