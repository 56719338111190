import React from 'react';
import { Button, Box, Typography,IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Warning from '../../assets/Warning.png';
import PaymentReview from '../../assets/PaymentReview.png';

const PlanExpiry = () => {
    const navigate = useNavigate();
    const [planExpiry, setPlanExpiry] = useState(false);
    const [paymentPending, setPaymentPending] = useState(false);

    const userDetailString = localStorage.getItem("UserDetail");
    const userDetail = userDetailString ? JSON.parse(userDetailString) : {};
    
    useEffect(() => {
        const planData = userDetail.is_plan_expired;
        const paymentData = userDetail.is_payment_pending;
        
        const parsedPlanData = planData ? JSON.parse(planData) : null;
        const parsedPaymentData = paymentData ? JSON.parse(paymentData) : null;
        
        setPlanExpiry(parsedPlanData);
        setPaymentPending(parsedPaymentData);
    }, []);

    const handleClose = () => {
       localStorage.clear();
       window.location.reload();
       navigate('/')
    };

    return (
        <>
            {planExpiry && !paymentPending && (
                <Box sx={{ position: 'relative' }}>
                     <IconButton 
                        onClick={handleClose}
                        sx={{
                            position: 'absolute',
                            top: {xs:-20,sm:-10,md:-10},
                            left: {xs:180,sm:300,md:450},
                            zIndex: 1000,
                            color: '#000'
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                    <Box>
                        <img src={Warning} alt="Warning" />
                        <Typography component="h2" sx={{ paddingTop:'5%',color: 'rgba(228, 39, 39, 1)',fontWeight:'bold', fontSize:{xs:20,sm:24,md: 24}, margin: 0 ,}}>
                            Your Plan Has Expired
                        </Typography>
                        <Typography component="p" sx={{ background: 'linear-gradient(90deg, #FF0909 0%, #B8614A 100%)',WebkitBackgroundClip:'text',WebkitTextFillColor:'transparent', fontSize:{xs:14,sm:16,md: 16}, margin: 0, fontWeight: 'bold' }}>
                            Please Renew Your Plan
                        </Typography>
                        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', gap: 3, pb: 5, pt: 3 }}>
                            <Button variant="contained" href="/subscription" target="_blank"
                                sx={{
                                    backgroundColor: 'rgba(37, 95, 148, 1)',
                                    borderRadius: '15px',
                                    '&:hover': {
                                        backgroundColor: 'rgba(37, 95, 148, 1)',
                                        color: 'white',
                                    },
                                }}
                            >
                                Renew Plan
                            </Button>
                            <Button variant="contained" href="https://wa.me/message/YVSLTCOJGS7SB1" target="_blank"
                                sx={{
                                    backgroundColor: 'rgba(37, 95, 148, 1)',
                                    borderRadius: '15px',
                                    '&:hover': {
                                        backgroundColor: 'rgba(37, 95, 148, 1)',
                                        color: 'white',
                                    },
                                }}
                            >
                                Contact Sales
                            </Button>
                        </Box>
                    </Box>
                </Box>
            )}

            {paymentPending && (
                <Box sx={{ position: 'relative' }}>
                     <IconButton 
                        onClick={handleClose}
                        sx={{
                            position: 'absolute',
                            top: {xs:-20,sm:-10,md:-10},
                            left: {xs:180,sm:300,md:500},
                            zIndex: 1000,
                            color: '#000'
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                    <Box>
                        <img src={PaymentReview} alt='Payment Review' style={{ width: '50%', height: 'auto' }} />
                        <Typography component="h2" sx={{ fontWeight:'bold',background: 'linear-gradient(90deg, #094FB7 0%, #5183CE 50%, #1155B9 100%)',WebkitBackgroundClip:'text',WebkitTextFillColor:'transparent', fontSize:{xs:20,sm:24,md: 30}, margin: 0 }}>
                            Payment In Review
                        </Typography>
                        <Typography component="p" sx={{background: 'linear-gradient(90deg, #094FB7 0%, #5183CE 50%, #1155B9 100%)',WebkitBackgroundClip:'text',WebkitTextFillColor:'transparent', fontSize:{xs:14,sm:16,md: 16}, margin: 0, fontWeight: 'bold' }}>
                            Your payment is being processed
                        </Typography>
                        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', gap: 3, pb: 5, pt: 3 }}>
                            <Button variant="contained" href="https://wa.me/message/YVSLTCOJGS7SB1" target="_blank"
                                sx={{
                                    background: 'linear-gradient(90deg, #65A3DD 0%, #1584D4 48.6%, #5D8FBD 100%)',
                                    borderRadius: '15px',
                                    '&:hover': {
                                        boxShadow: 'none',
                                        color: 'white',
                                    },
                                }}
                            >
                                Contact Support
                            </Button>
                        </Box>
                    </Box>
                </Box>
            )}
        </>
    );
};

export default PlanExpiry;
