export const employeeNavList = [
    {
        title: "Search",
        goTo: "/employeeSearch",
        icon: "fa fa-address-card",
      },
      {
        title: "Dashboard",
        goTo: "/employeeDashboard",
        icon: "fa fa-tachometer",
      },
]