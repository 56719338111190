import React,{ useEffect }from 'react';
import NavBar from './NavBar';
import HeroSection from './HeroSection';  
import OurNetwork from './OurNetwork';
import KeyFeatures from './keyfeatures';
import HomePageFooter from './HomePageFooter';
import TheProblem from './TheProblem';
import WhyChooseUs from './WhyChooseUs';
import Termsandpolicy from "../terms_policy";
import { useLocation } from "react-router-dom";

const MainHomepage = () => {
  const location = useLocation();
  useEffect(() => {
    const elementId = location.hash.substring(1); // Get the element id from the hash
    const element = document.getElementById(elementId);

    if (element) element.scrollIntoView({ behavior: "smooth" });
  }, [location]); // Listen for changes in the location
  return (
    <div>
      <NavBar />
      <HeroSection />
      <OurNetwork/>
      <div id="about" >
        <TheProblem/>
      </div>
      <KeyFeatures/>
      <WhyChooseUs/>
      <HomePageFooter/>
      <Termsandpolicy />
      {/* <FeaturesSection /> */}
      {/* <Footer /> */}
    </div>
  );
};

export default MainHomepage;
