import React from "react";
import '../../styles/scoreDetails.css'
// import './pending.scss'
import PropTypes from "prop-types"

const PendingReceivables = ({pendingReceivables,totalCustomer}) => {
  return (
    <>
    
      <div className="styleforheading">Critical Dues / Pending Dues</div>
      <div className="main">
        <h5>{pendingReceivables} approx.</h5>
        <h5>From {totalCustomer} Customers</h5>
    </div>
    </>
  );
};

export default PendingReceivables;

PendingReceivables.propTypes = {
  pendingReceivables:PropTypes.string.isRequired
}