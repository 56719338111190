import { useEffect } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import CssBaseline from "@mui/material/CssBaseline";
import GlobalStyles from "@mui/material/GlobalStyles";

import { createTheme, ThemeProvider } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import { getSubscriptionAction } from "../../store/actions";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";

function SubscriptionCard({ item, index }) {
  const navigate = useNavigate();

  return (
    <Card
      sx={{
        margin: "10px",
        width: "280px",
        height: "430px",
        display: "flex",
        flexDirection: "column",
        minHeight: "100%",
        borderRadius: "10px",
        boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
        border: "1px solid #e0e0e0",
        overflow: "hidden",
        transition: "transform 0.2s ease-in-out",
        justifyContent: "space-between",
        "&:hover": {
          transform: "scale(1.05)",
        },
      }}
    >
      <CardHeader
        title={`${item.type}`}
        titleTypographyProps={{
          align: "center",
          sx: {
            fontSize: "1.5rem",
            fontWeight: "bold",
            background: "linear-gradient(90deg, #0057ED 0%, #2C5172 100%)", // Same gradient as the button
            WebkitBackgroundClip: "text",
            WebkitTextFillColor: "transparent",
            textOverflow: "ellipsis",
          },
        }}
      />
      <Typography sx={{fontSize: "1rem",fontWeight: "bold",textAlign:'center',marginLeft:'2%',height:'12%'}}>
      {`${item.name}`}</Typography>
      <CardContent sx={{ flexGrow: 1, paddingBottom: "0" }}>
        <Box
          sx={{
            flexGrow: 1,
            textAlign: "center",
            backgroundColor: "#fff",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "space-between", // Ensure content is spaced evenly
          }}
        >
          <>
            <Typography
              component="h2"
              variant="h5"
              sx={{
                textDecoration: "line-through",
                color: "#888888",
              }}
            >
              {`₹${item.price}/year`}
            </Typography>
            <Typography
              component="p"
              variant="subtitle1"
              sx={{
                textAlign: "center",
                fontWeight: "bold",
                color: "#d90429",
              }}
            >
              {`Save ${Math.round(
                ((item.price - item.offer_price) / item.price) * 100
              )}% on the first-year subscription`}
            </Typography>

            <Typography
              component="h2"
              variant="h4"
              sx={{
                fontWeight: "bold",
                fontSize: "1.5rem",
                background: "linear-gradient(90deg, #0057ED 0%, #2C5172 100%)", // Same gradient as button
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
              }}
            >
              {`₹${item.offer_price}/year`}
            </Typography>

            <div style={{  marginTop: "0%" }}>
              <ul
                style={{ listStyle: "none", padding: 0 }}
              >
                {item.description.map((descItem) => (
                  <li
                    key={descItem}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      marginBottom: "8px",
                    }}
                  >
                    <Typography
                      component="span"
                      sx={{ color: "#34a0a4", mr: 1 }}
                    >
                      ✓
                    </Typography>
                    {descItem}
                  </li>
                ))}
              </ul>
            </div>
          </>
        </Box>
      </CardContent>
      {/* Button placed at the bottom */}
      <Button
        variant="contained"
        sx={{
          backgroundColor: "#274c77",
          width: "70%",
          fontSize: "1rem",
          fontWeight: "bold",
          color: "#ffffff",
          margin: "10px auto",
          background: "linear-gradient(90deg, #0057ED 0%, #2C5172 100%)",
          borderRadius: "20px",
          "&:hover": {
            background: "linear-gradient(90deg, #2C5172 0%, #0057ED 100%)", // Inverted gradient on hover
          },
        }}
        onClick={() => navigate("/register")}
      >
        {item.display_text}
      </Button>
    </Card>
  );
}

const defaultTheme = createTheme();

export default function PricingPolicy() {
  const dispatch = useDispatch();
  const { successData: subscriptionData } =
    useSelector((state) => state.getSubscriptionData) || {};

  useEffect(() => {
    dispatch(getSubscriptionAction());
  }, [dispatch]);

  const sortedSubscriptionData = [...subscriptionData?.result || []].sort((a, b) => a.id - b.id);

  return (
    <ThemeProvider theme={defaultTheme}>
      <GlobalStyles
        styles={{ ul: { margin: 0, padding: 0, listStyle: "none" } }}
      />
      <CssBaseline />

      <div className="SubscriptionCardContainer">
        {sortedSubscriptionData?.
        filter((item) => item.is_active)
          .map((item, index) => (
            <SubscriptionCard key={item.id} item={item} index={index} />
          ))}
      </div>
    </ThemeProvider>
  );
}

SubscriptionCard.propTypes = {
  item: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
};
