import React from 'react';
import { Typography, Box, IconButton, Table, TableBody, TableRow, TableCell } from '@mui/material';
import { toast } from 'react-toastify';
import { ShareOutlined, PrintOutlined, DownloadOutlined } from '@mui/icons-material';

function Consent() {
  const handleDownloadConsent = () => {
    window.open('/documents/consentForm.docx', '_blank');
  };

  const handlePrintConsent = () => {
    const printWindow = window.open('/documents/consentForm.docx', '_blank');
    printWindow.onload = () => {
      printWindow.print();
    };
  };

  const handleShareConsent = async () => {
    if (navigator.share) {
      try {
        await navigator.share({
          title: 'Consent Form',
          text: 'Please check this consent form',
          url: window.location.origin + '/documents/consentForm.docx'
        });
      } catch (error) {
        console.log('Error sharing:', error);
      }
    } else {
      navigator.clipboard.writeText(window.location.origin + '/documents/consentForm.docx');
      toast.success('Link copied to clipboard!');
    }
  };

  return (
    <>
      <Box>
        <Box sx={{ display: 'flex',mt:'2%'}}>
        <Typography  sx={{marginLeft: {xs:'5%',sm:'20%',md:'35%'},fontSize:{xs:'1rem',sm:'1.5rem',md:'2rem',color:'black'} }}>Consent Form</Typography>
        <Box 
          component="span" 
          display="flex"
          flexWrap="wrap"
          marginLeft="25%"
          gap="10px"
        >
          <IconButton onClick={handleDownloadConsent} size="small" title="Download">
            <DownloadOutlined sx={{ height: '18px', width: '18px',color:'black' }} />
          </IconButton>
          <IconButton onClick={handlePrintConsent} size="small" title="Print">
            <PrintOutlined sx={{ height: '18px', width: '18px',color:'black' }} />
          </IconButton>
          <IconButton onClick={handleShareConsent} size="small" title="Share">
            <ShareOutlined sx={{ height: '18px', width: '18px',color:'black' }} />
          </IconButton>
        </Box>
      </Box>
      </Box>

      <Box sx={{ width: {xs:'100%',sm:'120%',md:'85%'}, marginLeft: {xs:'0%',sm:'0%',md:'10%'}, marginTop: '5%' }}>
        <Typography sx={{ marginBottom: '5%' ,color:'black'}}>Subject: Consent Form for Partner Rating on ClearBiz</Typography>
        <Typography color='black'>Dear ………………………………………………. [Partner Y's Name],</Typography>
        <Typography sx={{ textIndent: '10%', mt: '2%',color:'black' }}> I hope this message finds you well. We greatly value your association with our company
          …………………………………………. [Your Name], the Products and Services provide by us. We are writing to inform you of an
          important process related to Rating platform ClearBiz.AI.
        </Typography>
        <Typography sx={{ textIndent: '10%', mt: '2%' ,color:'black'}}>
          As a registered user, ……………………………………….. [Your Name], intends to associate you as a partner on ClearBiz. This
          partnership may involve the rating of your services and the sharing of information about your
          business on the platform. To ensure transparency and compliance with all applicable regulations,
          we kindly request your consent for this partner association and the subsequent rating process.
        </Typography>
        <Typography sx={{ mt: '5%' ,color:'black'}}><b>By signing the form, you acknowledge the following:</b></Typography>
        <Typography mt='2%' color='black'>1. You are aware that you will be rated by users of ClearBiz.</Typography>
        <Typography color='black'>2. Information about your business may be shared on the platform ClearBiz.</Typography>
        <Typography color='black'>3. You agree to comply with the ClearBiz's Terms and Policies.</Typography>
        <Typography color='black'>Your signed consent form is essential for the successful completion of this partner association. We appreciate your cooperation in this matter.</Typography>
        <Typography color='black' mt='5%'><b>To provide your consent, sign consent form and return it to us.</b></Typography>

        <Table sx={{ width: '100%',border:1, borderCollapse: 'collapse', mt: 3,backgroundColor:'#fff' }}>
          <TableBody>
            <TableRow>
              <TableCell sx={{ width: '70%', padding: 0 }}>
                <Table size="small" >
                  <TableBody>
                    <TableRow>
                      <TableCell sx={{ width: '30%', borderRight: 1, borderColor: 'black' }}>Name</TableCell>
                      <TableCell sx={{ borderBottom: 1, borderColor: 'black',borderRight:1 }}></TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell sx={{ width: '30%', borderBottom: 1, borderColor: 'black', borderRight: 1 }}>Firm Name</TableCell>
                      <TableCell sx={{ borderBottom: 1, borderColor: 'black',borderRight:1 }}></TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell sx={{ width: '30%', borderColor: 'black', borderRight: 1 }}>Place</TableCell>
                      <TableCell sx={{ borderBottom: 1, borderColor: 'black',borderRight:1 }}></TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell sx={{ width: '30%', borderColor: 'black', borderRight: 1 }}>Date</TableCell>
                      <TableCell sx={{ borderBottom: 1, borderColor: 'black',borderRight:1 }}></TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableCell>
              <TableCell sx={{ width: '30%', textAlign: 'center', verticalAlign: 'top', padding: 2, borderBottom: 1, borderColor: 'black' }}>
                Stamp With Signature:
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
        <Typography mt='5%' color='black'>If you have any questions or require further information, please do not hesitate to contact us.  </Typography>
        <Typography mt='2%' color='black'>Thank you for your trust and participation on ClearBiz.  </Typography>
        <Typography sx={{textIndent:{xs:'65%',md:'85%',sm:'85%'},mt:'10%',color:'black'}}>Best Regards,  </Typography>
        <Typography sx={{textIndent:{xs:'50%',sm:'75%',md:'75%'},mt:'8%',color:'black'}}>………………………………………….</Typography>
        <Typography sx={{textIndent:{xs:'65%',sm:'80%',md:'87%'},mt:'2%',color:'black'}}>[Your Name] </Typography>
      </Box>
    </>
  );
}

export default Consent;
