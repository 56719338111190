import React from 'react';
import Container from '@mui/material/Container';
import { Box, Link, useTheme, useMediaQuery, } from '@mui/material';

export default function Termsandpolicy() {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md')); // for mobile and tablet

  const linkStyle = {
    textDecoration: 'none',
    whiteSpace: 'nowrap',
    color: '#fff',
    fontSize: isSmallScreen ? '0.7rem' : '1rem',
  };

  return (
    <Box sx={{ width: '100%', backgroundColor: '#2b4257', color: '#fff', px: 3, py: 2 }}>
      <Container
        maxWidth="md"
        component="footer"
        sx={{
          display: 'flex',
          justifyContent: 'center',
          width: '100%',
          backgroundColor: '#2b4257',
          maxWidth: '100% !important',
        }}
      >
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="center"
          gap={{ xs: '0.7rem', sm: '2rem' }}
          sx={{ flexWrap: 'wrap', textAlign: 'center' }}
        >
          <Link href="/about/pricing" target="_blank" rel="noopener noreferrer" style={linkStyle}>
            Product & Pricing
          </Link>
          <Link href="/about/terms" target="_blank" rel="noopener noreferrer" style={linkStyle}>
            Terms of Use
          </Link>
          <Link href="/about/privacy" target="_blank" rel="noopener noreferrer" style={linkStyle}>
            Privacy Policy
          </Link>
          <Link href="/about/refund" target="_blank" rel="noopener noreferrer" style={linkStyle}>
            Refund
          </Link>
          <Link href="/about/shipping" target="_blank" rel="noopener noreferrer" style={linkStyle}>
            Shipping
          </Link>
          <Link href="/about/contact" target="_blank" rel="noopener noreferrer" style={linkStyle}>
            Contact
          </Link>
          <Link href="/about/consent" target="_blank" rel="noopener noreferrer" style={linkStyle}>
            Consent Form
          </Link>
        </Box>
      </Container>
    </Box>
  );
}
