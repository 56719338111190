   
import sliceCreator from '../index.js';
import {BULK_UPLOAD} from "../../../constants"

const _bulkUpload = sliceCreator('BULK_UPLOAD', BULK_UPLOAD, 'post');

const { asyncAction: bulkUploadAction, reducer, clearData: clearBulkUploadAction } = _bulkUpload;

export default reducer;

export {
    bulkUploadAction,
    clearBulkUploadAction
}