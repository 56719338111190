import React, { useState } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import SideBar from '../../../components/sidebar';
import './styles.css';
import notfound1 from '../../../assets/notfound1.png';
import { useNavigate } from 'react-router-dom';

const EmployeeSearch = () => {
  const navigate = useNavigate();
  const [searchInput, setSearchInput] = useState('');
  const [employeeFound, setEmployeeFound] = useState(true); 

  const searchEmployee = (query) => {
    const found = query.toLowerCase() === 'john doe';
    return found;
  };

  const handleSearch = () => {
    if (searchInput.trim() === '') {
      setEmployeeFound(false); 
      return;
    }

    const isEmployeeFound = searchEmployee(searchInput);
    setEmployeeFound(isEmployeeFound);

    if (isEmployeeFound) {
      navigate('/addToOrganization'); // Navigate if employee is found
    }
  };

  return (
    <div style={{ minHeight: '90vh' }}>
      <SideBar />
      {employeeFound ? (
        <div className="employee-container">
          <h1 className="employee-title">Employee Search</h1>
          <div className="search-box">
            <input
              type="text"
              placeholder="Search..."
              className="search-field"
              value={searchInput}
              onChange={(e) => setSearchInput(e.target.value)}
            />
            <button className="search-button" onClick={handleSearch}>
              <SearchIcon />
              Search
            </button>
          </div>
        </div>
      ) : (
        <div
          style={{
            marginLeft: '10%',
            justifyContent: 'center',
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
            minHeight: '100vh',
          }}
        >
          <img src={notfound1} alt="No Employee Found" style={{ width: '100%', maxWidth: '300px' }} />
          <h3
            style={{
              background: 'linear-gradient(90deg, #2B4257 0%, #625DBD 100%)',
              WebkitBackgroundClip: 'text',
              WebkitTextFillColor: 'transparent',
            }}
          >
            No Results Found
          </h3>
          <h6 style={{ marginLeft: {sm:"0%",md:'0%',xs:'10%'} }}>
            We couldn’t find your employee. Add a new one
          </h6>
          <button
            onClick={() => {
              navigate('/newEmployee');
            }}
            style={{
              background: 'linear-gradient(90deg, #553EBD 0%, #7A538E 100%)',
              color: '#fff',
              borderRadius: '15px',
              margin: '1% 0% 6% 0%',
              padding: '0.5% 5%',
            }}
          >
            Create Employee
          </button>
        </div>
      )}
    </div>
  );
};

export default EmployeeSearch;
